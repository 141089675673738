import {AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {combineLatestWith, tap} from 'rxjs/operators';
import {PortStatus} from '../../../layer/classes/port-status';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {PortStatusSource} from '../../../layer/sources/port-status-source';
import {LayerService} from '../../../layer/services/layer.service';
import {CustomPopover} from '../../../layer/enum/custom-popover';
import {SubportStatus} from '../../../layer/classes/subports-status';
import {FileDownload} from '../../../../classes/file-download';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {Router} from '@angular/router';
import {ReportFilter} from '../../enums/report-filter.enum';
import {BehaviorSubject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'eaglei-port-status-report',
    templateUrl: './port-status-report.component.html',
    styleUrls: ['../reports.scss', './port-status-report.component.scss'],
})
export class PortStatusReportComponent extends Report<PortStatus> implements OnInit, AfterViewInit {
    // Html Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private router = inject(Router);

    // Table Properties
    public readonly columns: string[] = ['region', 'port', 'portStatus', 'subports', 'comments', 'lastUpdated', 'zonePage', 'toggle'];
    private activeEvent: PortStatus;
    public portDataSource: MatTableDataSource<SubportStatus> = new MatTableDataSource<SubportStatus>();

    // Filter Properties
    public readonly statusStrings: string[] = ['Default', 'Open', 'Restrictions', 'Closed'];
    public portStatuses: number[] = [1, 2, 3];
    public portRegions: string[] = [];
    public selectedStatus: number[] = [];
    public selectedRegions: string[] = [];
    private regionsFromWidget: string[] = [];

    // Map Properties
    protected mapReady$: BehaviorSubject<L.Map> = new BehaviorSubject<L.Map>(undefined);
    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: PortStatusSource;
    public dataLoaded: boolean;
    public showMask: boolean = true;
    public ports: PortStatus[] = [];
    protected eventId: number;

    constructor(public reportService: ReportService, private host: ElementRef, private layerService: LayerService) {
        super();

        this.mapOptions = new MapOptions().setZoom(4, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        const routeFilters: IReportNavigationConfig[] = this.router?.getCurrentNavigation()?.extras['state'] as any;
        if (routeFilters) {
            routeFilters.forEach((filter) => {
                switch (filter.type) {
                    case ReportFilter.TEXT_SEARCH:
                        this.selectedStatus = (filter.value as string).split(',').map((statusCode) => parseInt(statusCode));
                        break;
                    case ReportFilter.EVENT_ID:
                        this.eventId = filter.value as number;
                        break;
                    case ReportFilter.STATE:
                        this.regionsFromWidget = (filter.value as string).split(',');
                        break;
                    // case ReportFilter.CAPACITY:
                    //   this.selectedCapacity = [true];
                    //   break;
                }
            });
        }

        this.layerService
            .getLayerByHandle('portStatus')
            .pipe(combineLatestWith(this.mapReady$), takeUntilDestroyed())
            .subscribe((events: [LeafletMapLayer, L.Map]) => {
                this.mapLayer = events[0];
                this.mapLayer.startLoading();
                this.mapLayer.customPopoverId = CustomPopover.PORT_STATUS;
                this.layerSource = new PortStatusSource(events[0]);
                this.layerSource.mapRef = events[1];
            });
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
    }

    ngAfterViewInit(): void {
        this.selectedStatus = this.selectedStatus.length !== 0 ? this.selectedStatus : this.portStatuses.slice();

        this.getPorts();
    }

    // API calls
    /**
     * turns on the loading message and fetches the relevant port status data.
     */
    private getPorts(): void {
        this.dataLoaded = false;

        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.reportService
            .portStatusData(true)
            .pipe(
                tap((uscgPortCaptains: PortStatus[]) => {
                    HttpInterceptorService.deleteFromInterceptor(this.uiHandle);
                    this.eventId = undefined;
                    this.portRegions = Array.from<string>(new Set(uscgPortCaptains.map((item) => item.name))).sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                    );
                    this.selectedRegions = this.regionsFromWidget.length !== 0 ? this.regionsFromWidget : this.portRegions.slice();
                    this.regionsFromWidget = [];
                })
            )
            .subscribe((res) => {
                const ports = this.setPortStatuses(res);
                this.initializeData(ports);

                // Update map
                this.layerSource.processFeatures(ports);
                this.layerSource.changeOpacity(0.8);
                this.layerSource.addToMap(true);
                this.filterPortMap();

                this.ports = ports;
                this.dataLoaded = true;
                this.showMask = ports.length === 0;
            });
    }

    // Table Methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: PortStatus[]): void {
        // noinspection DuplicatedCode
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }
        this.filterPorts(this.dataSource.filter);
    }

    /**
     * The logic behind how the table is filtered, is called when the filter property on the dataSource is changed.
     * @param data The recent event being tested.
     * @param text The text being searched for.
     */
    private filterPredicate(data: PortStatus, text: string): boolean {
        let portStatusCheck = false;
        let portRegionCheck = false;

        if (this.selectedRegions.length > 0) {
            portRegionCheck = this.selectedRegions.includes(data.name);
        }

        if (this.selectedStatus.length > 0) {
            portStatusCheck = this.selectedStatus.some((r) => data.status.includes(r));
        }

        return portRegionCheck && portStatusCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: PortStatus, header: string): string | number {
        switch (header) {
            case 'region':
                return data.name.valueOf();
            case 'subports':
                return data.ports.length;
            default:
                return '';
        }
    }

    // Filter Ports
    /**
     * Called when the ports.ts dropdown is updated and fetches new data.
     * @param value The new ports.ts array
     */
    public updateStatus(value: number[]): void {
        this.selectedStatus = value;
        this.filterPorts(this.dataSource.filter);
        this.filterPortMap();
    }

    // Filter Ports
    /**
     * Called when the regions dropdown is updated and fetches new data.
     * @param value The new ports.ts array
     */
    public updateRegions(value: string[]): void {
        this.selectedRegions = value;
        this.filterPorts(this.dataSource.filter);
        this.filterPortMap();
    }

    // Filter Ports
    /**
     * Called when the ports.ts dropdown is updated and fetches new data.
     */
    public filterPortMap(): void {
        let filteredPorts = [];

        if (this.selectedStatus.length > 0 && this.selectedStatus.length > 0) {
            filteredPorts = this.dataSource.data.filter((e) => this.selectedStatus.some((r) => e.status.includes(r)));
            filteredPorts = filteredPorts.filter((e) => this.selectedRegions.includes(e.name));
            this.ports = filteredPorts;
        }

        this.layerSource.clearMap();
        this.layerSource.processFeatures(filteredPorts);
        this.layerSource.changeOpacity(0.8);
        this.layerSource.addToMap(true);
    }

    /**
     * sets the filter on the dataSource and triggers the table filter.
     * This should be called by all filter update methods.
     * @param text The text being passed to the dataSource filter.
     */
    public filterPorts(text: string): void {
        text = text === '' ? ' ' : text;

        this.dataSource.filter = text;
    }

    /**
     * Changes the expanded row in the table, if the row is already expanded, it will close it
     * @param row The row to be expanded
     */
    public toggleActiveReport(row: PortStatus): void {
        if (this.activeEvent && this.activeEvent.id === row.id) {
            this.activeEvent = undefined;
            this.portDataSource.data = [];
        } else {
            this.activeEvent = row;
            this.portDataSource.data = row.ports;
        }
    }

    /**
     * Checks to see if a row is expanded
     * @param event the row that is bring checked for expansion.
     */
    public isActiveEvent(event: PortStatus): boolean {
        return this.activeEvent && this.activeEvent.id === event.id;
    }

    // Set port status
    // noinspection JSMethodCanBeStatic
    /**
     * Gets the port statuses and assigns it to each region.
     * @param ports The ports.ts data
     */
    private setPortStatuses(ports): any {
        let uniqueItems;

        for (const item1 of ports) {
            uniqueItems = [...new Set(item1.ports.map((item) => item.status))]; // map all options
            item1.status = uniqueItems;
        }
        return ports;
    }

    // Loading Text
    /**
     * Sets the loading or no data message
     */
    public getMaskText(): string {
        return this.dataLoaded && this.ports.length === 0 ? 'No ports.ts found matching filters' : 'Loading...';
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable(): void {
        let data: string = ['Region', 'Port', 'Port Status', 'Subports', 'Comments', 'Last Updated', 'Zone Page'].join() + '\n';

        // Each Region
        this.dataSource.filteredData.forEach((region) => {
            const info = [
                FileDownload.formatCsvCell(region.name),
                FileDownload.formatCsvCell(' '),
                FileDownload.formatCsvCell(' '),
                FileDownload.formatCsvCell(region.ports.length),
                FileDownload.formatCsvCell(' '),
                FileDownload.formatCsvCell(' '),
                FileDownload.formatCsvCell(region.url),
            ];
            data += `${info.join()}\n`;

            // Each Port in the Region
            region.ports.forEach((port) => {
                const portInfo = [
                    FileDownload.formatCsvCell(' '),
                    FileDownload.formatCsvCell(port.name),
                    FileDownload.formatCsvCell(this.statusStrings[port.status]),
                    FileDownload.formatCsvCell(' '),
                    FileDownload.formatCsvCell(FileDownload.formatCsvCell(port.comments)),
                    FileDownload.formatCsvCell(Report.momentPipe.transform(port.lastModDate, 'MM/DD/YYYY')),
                    FileDownload.formatCsvCell(' '),
                ];
                data += `${portInfo.join()}\n`;
            });
        });

        FileDownload.downloadCSV('portStatus', data, this.attributionUrl);
    }
}
