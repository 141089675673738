<div class="filter-bar ignore-last">
    <section class="filters" [formGroup]="filterGroup">
        <mat-form-field class="name-search-field" floatLabel="always">
            <mat-label>Search Refinery Name</mat-label>
            <!--suppress TypeScriptUnresolvedVariable -->
            <input matInput placeholder="Refinery Name" formControlName="nameSearch" />
        </mat-form-field>

        <eaglei-date-range
            [start]="startDate"
            [min]="ongService.minDate"
            [max]="maxDate"
            [startPlaceholder]="'Measurement Date'"
            [singlePicker]="true"
            (change)="updateDateRange($event)">
        </eaglei-date-range>

        <mat-form-field class="filter-select">
            <mat-label>Filter Status</mat-label>
            <mat-select [value]="selectedStatus" (selectionChange)="changeSelectedStatus($event)">
                <mat-option [value]="'all'">All</mat-option>
                <mat-option [value]="'online'">Online</mat-option>
                <mat-option [value]="'impacted'">Reduced Capacity</mat-option>
                <mat-option [value]="'offline'">Offline</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container" [class.invisible]="dataSource?.filteredData?.length === 0">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" multiTemplateDataRows>
                    <ng-container matColumnDef="expand-icon">
                        <th mat-header-cell *matHeaderCellDef class="ei-icon-column"></th>
                        <td mat-cell *matCellDef="let info" class="ei-icon-column">
                            <fa-icon [icon]="info === selectedRow ? 'chevron-down' : 'chevron-right'"></fa-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Status</th>
                        <td mat-cell *matCellDef="let info" class="ei-sm-column">
                            <div
                                class="status-icon"
                                [class.offline]="info.status === 'Offline'"
                                [class.impacted]="info.status === 'Reduced Capacity'">
                                <fa-icon
                                    [icon]="
                                        info.status === 'Online'
                                            ? 'check'
                                            : info.status === 'Reduced Capacity'
                                            ? 'exclamation-triangle'
                                            : 'ban'
                                    ">
                                </fa-icon>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.colspan]="3">
                            Name
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" [attr.colspan]="3">{{ info.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="capacity">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Total Capacity (bpd)</th>
                        <td mat-cell *matCellDef="let info" [attr.colspan]="2">{{ info.total_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="impacted_capacity">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Impacted Capacity (bpd)</th>
                        <td mat-cell *matCellDef="let info" [attr.colspan]="2">{{ info.offline_capacity | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="percent_impacted">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Percent Impacted Capacity
                            <eaglei-table-sort-header [columnName]="'percent_impacted'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.offline_capacity / info.total_capacity | percent : '0.0-2' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let info" [attr.colspan]="10">
                            <div class="hide-expanded-row" [class.show-expanded-row]="selectedRow === info">
                                <eaglei-oil-refinery-status-table
                                    *ngIf="selectedRow === info"
                                    [units]="info.units"></eaglei-oil-refinery-status-table>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        class="clickable"
                        (click)="toggleExpandedRow(row)"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution" [class.invisible]="dataSource?.filteredData?.length === 0">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>

            <div class="auto-mask" *ngIf="dataSource?.filteredData?.length === 0">
                <p class="mask-message">No Refineries match your current filters.</p>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!-- Export Menu -->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
