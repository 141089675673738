<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select">
            <input matInput (keyup)="filterData($event.target.value)" placeholder="Filter Utilities" />
            <fa-icon matSuffix class="icon input-search-icon" icon="search" inline></fa-icon>
        </mat-form-field>

        <eaglei-date-range (change)="updateDates($event)"></eaglei-date-range>

        <mat-form-field class="date-range-mode">
            <mat-label>Date Range Setting</mat-label>
            <mat-select placeholder="Date Range Setting" [formControl]="dateRangeControl">
                <mat-option
                    value="inclusive"
                    matTooltipPosition="right"
                    matTooltip="Shows all utilties that have repeating outages within the date range">
                    Inclusive
                </mat-option>
                <mat-option
                    value="match"
                    matTooltipPosition="right"
                    matTooltip="Shows only utilities where the first and last occurance match the start and end date respectivly">
                    Exact Match
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <button mat-stroked-button [matMenuTriggerFor]="menu" matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    class="test-design"
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="distinct"
                    matSortDirection="desc">
                    <ng-container matColumnDef="eagle_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            EAGLE-I&trade; ID
                            <eaglei-table-sort-header [columnName]="'eagle_id'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">{{ utility.eagleiId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="sub_utility_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Sub-Utility ID
                            <eaglei-table-sort-header [columnName]="'sub_utility_id'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">{{ utility.subUtilityId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="utility_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-lg-column">
                            Utility Name
                            <eaglei-table-sort-header [columnName]="'utility_name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            #utilityName
                            mat-cell
                            *matCellDef="let utility"
                            [matTooltip]="utility.utilityName"
                            [matTooltipDisabled]="!hasEllipsis(utilityName)"
                            matTooltipPosition="above"
                            matTooltipShowDelay="250"
                            class="ei-lg-column ellipsis">
                            {{ utility.utilityName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="county_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county_name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.countyName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.state }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customers_out">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Customers Out
                            <eaglei-table-sort-header [columnName]="'customers_out'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.customersOut | eagleiNumber }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="distinct">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Distinct Count
                            <eaglei-table-sort-header [columnName]="'distinct'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.duplicateCount }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="percent_repeated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            % of Outage Reports
                            <eaglei-table-sort-header [columnName]="'percent_repeated'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.percentRepeated | percent : '1.2-2' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            First Occurence
                            <eaglei-table-sort-header [columnName]="'start'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.duplicateStart | momentDate }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="end">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Last Occurence
                            <eaglei-table-sort-header [columnName]="'end'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.duplicateEnd | momentDate }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu">
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
