<mat-form-field class="filter-select center" appearance="outline">
    <mat-label *ngIf="!!typeControl.value">FEMA Category</mat-label>
    <mat-select placeholder="Asset Type" [formControl]="typeControl" multiple>
        <mat-option *ngFor="let type of typeOptions" [value]="type">{{ type.toString() | replaceCharacter | titlecase }}</mat-option>
    </mat-select>
</mat-form-field>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="name"
                matSortDirection="asc">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        <span>Name</span>
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.name }}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
                        <span>Type</span>
                        <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.layerType | replaceCharacter }}</td>
                </ng-container>

                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by category">
                        <span>Category</span>
                        <eaglei-table-sort-header [columnName]="'category'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.femaType.toString() | replaceCharacter | titlecase }}</td>
                </ng-container>

                <ng-container matColumnDef="more-info">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let info">
                        <span class="fake-link" (click)="openAssetInfoModal(info)">View all info</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
            </table>
            <mat-paginator [hidePageSize]="true" [pageSize]="7" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
