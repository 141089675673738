import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {throttleTime} from 'rxjs/operators';

@Component({
    selector: 'eaglei-maintenance-info-modal',
    templateUrl: './maintenance-info-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './maintenance-info-modal.component.scss'],
})
export class MaintenanceInfoModalComponent implements AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    public maintenanceData: any;
    public locations: any;
    public tariffs: any;
    public entities: any;

    constructor(public dialogRef: MatDialogRef<MaintenanceInfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.maintenanceData = data.event;

        this.locations = this.getJsonFromString(this.maintenanceData.locations);
        this.tariffs = this.getJsonFromString(this.maintenanceData.tariff_zones);
        this.entities = this.getJsonFromString(this.maintenanceData.other_entities);
    }

    public ngAfterViewInit(): void {
        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => this.setModalHeight());
    }

    private getJsonFromString(data: string): any {
        // Old Regex - /,(?![^{]*})/gm
        // Grabs the data and splits the data on commas that aren't included in the object to seperate the objects and parse correctly
        return data
            .replace(/[\[-\]]/g, '')
            .split(/(?!\'[\w\s]*[\\']*[\w\s]*),(?![\w\s]*[\\']*[\w\s]*\')/gm)
            .filter((l) => l.trim().length > 0)
            .map((l) => JSON.parse(l.replace(/'/gm, '"')));
    }

    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }
}
