import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OilRefineryStatusTableComponent} from './components/oil-refinery-status-table/oil-refinery-status-table.component';
import {SharedModule} from '../../shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [OilRefineryStatusTableComponent],
    exports: [OilRefineryStatusTableComponent],
    imports: [CommonModule, SharedModule, FontAwesomeModule],
})
export class OngModule {}
