<div class="dialog-header">
    <span>{{ title }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content" #content>
    <div class="table-container">
        <table
            #materialTable
            mat-table
            [dataSource]="dataSource"
            matSort
            [matSortDisableClear]="true"
            [matSortActive]="defaultSort"
            [matSortDirection]="defaultSortDirection">
            <ng-container *ngFor="let def of columnDefs" [matColumnDef]="def.columnName">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    {{ def.displayName }}
                    <eaglei-table-sort-header [columnName]="def.columnName" [sort]="sort" *ngIf="def.sortValue"></eaglei-table-sort-header>
                </th>
                <td
                    mat-cell
                    *matCellDef="let data"
                    [matTooltip]="def.getValue(data)"
                    [matTooltipDisabled]="!def.hasTooltip"
                    matTooltipShowDelay="250"
                    matTooltipPosition="above">
                    <p class="ellipsis">{{ def.getValue(data) }}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
        </table>
    </div>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
