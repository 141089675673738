<!--Report Filters-->
<form [formGroup]="filterGroup" class="filter-bar ignore-last">
    <section class="filters">
        <mat-button-toggle-group formControlName="aggregationLevel">
            <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level">{{ level | aggregationLevel }}</mat-button-toggle>
        </mat-button-toggle-group>

        <eaglei-location-filter
            [newDesign]="true"
            [selectAllOption]="true"
            (locations)="changeSelectedLocations($event)"
            [defaultSelection]="statePreferences"></eaglei-location-filter>

        <eaglei-date-range
            [newDesign]="true"
            [showTime]="true"
            [min]="minDate"
            [max]="maxDate"
            (change)="updateDates($event)"></eaglei-date-range>

        <mat-form-field class="filter-select" [class.active]="filterGroup.controls.interval.value">
            <mat-label *ngIf="filterGroup.controls.interval.value">Hourly Increment</mat-label>
            <mat-select placeholder="Hourly Increment" formControlName="interval">
                <mat-option *ngFor="let increment of increments" [value]="increment">{{ increment }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="clickable override-toggle" (click)="toggleOverrides()">
            <fa-icon [icon]="overridesVisible ? 'eye' : 'eye-slash'"></fa-icon>
            <span>Show Overrides</span>
        </div>
    </section>

    <div
        matTooltip="Unable to export until snapshots are available"
        matTooltipPosition="above"
        matTooltipShowDelay="250"
        [matTooltipDisabled]="isFilterValid()">
        <button
            mat-stroked-button
            matTooltip="Export"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [disabled]="!isFilterValid()"
            [matMenuTriggerFor]="menu">
            <fa-icon icon="file-export"></fa-icon>
        </button>
    </div>
</form>

<!--Report Maps-->
<div class="maps">
    <div *ngFor="let i of numberOfSnapshots; index as index">
        <mat-card class="map-container" [class.hide-snapshot]="getSnapshot(index) === undefined">
            <ng-container
                *ngIf="dataLoaded; else loadingMasks"
                [ngTemplateOutlet]="maps"
                [ngTemplateOutletContext]="{snapshot: getSnapshot(index)}"></ng-container>
        </mat-card>
    </div>
</div>

<div [class.hide-snapshot]="!dataLoaded">
    <mat-paginator hidePageSize showFirstLastButtons (page)="pageSnapshots($event)"></mat-paginator>
</div>

<div class="auto-mask click-through" *ngIf="showMask">
    <p class="mask-message" [class.no-data]="this.dataLoaded && this.snapshots.length === 0">{{ getMaskText() }}</p>
</div>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button mat-menu-item (click)="exportData()">Export Snapshots As CSV</button>
</mat-menu>

<ng-template #maps let-snapshot="snapshot">
    <mat-card-subtitle>
        <span>{{ snapshot?.runStartTime | momentDate : 'ddd M/D/YY, h:mm A z' }}</span>
    </mat-card-subtitle>
    <mat-card-content style="height: 320px">
        <eaglei-leaflet-map (mapRef)="setMapRef(snapshot, $event)" [mapOptions]="mapOptions" [singleBaseLayer]="true"></eaglei-leaflet-map>
    </mat-card-content>
    <mat-card-footer>
        <eaglei-nom-legend [hideOverrideIcon]="true"></eaglei-nom-legend>
    </mat-card-footer>
</ng-template>

<ng-template #loadingMasks>
    <mat-card-content class="wide tall">
        <img class="loading-image" src="/dist/browser/images/icons/map-mask.png" alt="Map Loading Mask" />
    </mat-card-content>
</ng-template>
