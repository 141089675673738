<!--Report Filters-->
<div class="filter-bar ignore-last">
    <form class="filters">
        <mat-form-field class="name-search-field" floatLabel="always">
            <mat-label>Search Utilities</mat-label>
            <!--suppress TypeScriptUnresolvedVariable -->
            <input matInput placeholder="Utility Name or Acronym" (keyup)="filterData($event.target.value, true)" />
        </mat-form-field>

        <mat-button-toggle-group [value]="selectedAggregationLevel" (change)="changeAggregation($event)">
            <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level">
                {{ level | aggregationLevel }}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <eaglei-location-filter
            (locations)="changeSelectedLocations($event)"
            [defaultSelection]="selectedStates"
            [selectAllOption]="true"></eaglei-location-filter>

        <eaglei-date-range
            [showTime]="true"
            [singlePicker]="true"
            [startPlaceholder]="'End Date'"
            [start]="endDate"
            [min]="minDate"
            (change)="updateEndDate($event)"></eaglei-date-range>
    </form>

    <div class="action-buttons">
        <button
            *ngIf="!onMobile()"
            class="request-email"
            mat-icon-button
            matTooltip="Periodically send current selected report data for up to 7 days"
            matTooltipShowDelay="250"
            matTooltipPosition="above"
            (click)="openEmailModal()">
            <fa-icon icon="envelope"></fa-icon>
        </button>

        <button mat-icon-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
            <fa-icon icon="file-export"></fa-icon>
        </button>
    </div>
</div>

<div class="filters extra-row">
    <mat-chip [class.selected]="showOverrideColor" (click)="toggleOverrideColor()">
        <div>
            <fa-icon [icon]="['far', showOverrideColor ? 'eye' : 'eye-slash']"></fa-icon>
            <span class="chip-text">Overrides</span>
        </div>
    </mat-chip>
</div>

<div class="filters extra-row state-chip-list">
    <span class="filter-text">Filters: </span>
    <eaglei-filter-chip-list class="wide" [states]="selectedStates" (change)="changeSelectedLocations($event)"></eaglei-filter-chip-list>
</div>

<!--Report Layout-->
<div class="report-data">
    <ng-container [ngTemplateOutlet]="barChart"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!--Chart-->
<ng-template #barChart>
    <mat-card class="chart">
        <mat-card-subtitle>
            <mat-button-toggle-group [value]="selectedDayBack" [class.invisible]="maskOptions.showMask">
                <mat-button-toggle *ngFor="let day of daysBack" [value]="day.value" (change)="updateChartStartDate(day.value)">
                    {{ day.display }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-subtitle>
        <mat-card-content [class.invisible]="maskOptions.showMask">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="maskOptions.showMask">
            <span class="mask-message">
                <span>{{ maskOptions.text }}</span>
            </span>
        </div>
    </mat-card>
</ng-template>

<!--Table-->
<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="customersOut"
                    matSortDirection="desc">
                    <ng-container matColumnDef="active">
                        <th mat-header-cell class="icon-column" *matHeaderCellDef>
                            <mat-checkbox
                                class="header-checkbox"
                                color="primary"
                                [checked]="areAllChecked()"
                                [indeterminate]="isIndeterminate()"
                                (change)="toggleAllUtilities($event.checked)">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="icon-column">
                            <mat-checkbox
                                color="primary"
                                [checked]="isUtilitySelected(utility)"
                                (change)="toggleUtility(utility, $event.checked)"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="utility-name">
                            <div class="sort-header">
                                <span>Utility Name</span>
                                <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let utility"
                            class="utility-name"
                            [matTooltip]="utility.utilityName"
                            matTooltipPosition="after"
                            matTooltipShowDelay="250">
                            <ng-container *ngIf="utility.url; else noLink">
                                <a class="ellipsis utility-link" [href]="utility.url" target="_blank">{{ utility.utilityName }}</a>
                            </ng-container>
                            <ng-template #noLink>
                                <p class="ellipsis">{{ utility.utilityName }}</p>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="utilityId">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>EAGLE-I&trade; ID</span>
                                <eaglei-table-sort-header [columnName]="'utilityId'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td class="right-cell" mat-cell *matCellDef="let utility">
                            <div>
                                <p class="ellipsis">{{ utility.utilityId }}</p>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="region">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>FEMA Region</span>
                                <eaglei-table-sort-header [columnName]="'region'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell class="right-cell" *matCellDef="let utility">
                            <div>
                                <p class="ellipsis">{{ utility.region.id | numeral }}</p>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>State/Territory</span>
                                <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            <p class="ellipsis">{{ utility.stateName | stateName }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>County</span>
                                <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            <p class="ellipsis">{{ utility.countyName }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customersOut">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Customers Out</span>
                                <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let utility"
                            (click)="currentTooltip.toggle()"
                            [style.color]="utility.currentOutageHasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <span>{{ utility.currentOutage | eagleiNumber }} </span>
                                <img
                                    class="date-hover"
                                    src="dist/browser/images/icons/calendar.svg"
                                    alt="calendar"
                                    matTooltipShowDelay="250"
                                    #currentTooltip="matTooltip"
                                    [matTooltip]="utility.lastReportedOutageRunStartTime | momentDate"
                                    matTooltipPosition="above" />
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="percentOut">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Percent Out</span>
                                <eaglei-table-sort-header [columnName]="'percentOut'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            (click)="percentTooltip?.toggle()"
                            *matCellDef="let utility"
                            [style.color]="utility.currentOutageHasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <ng-container *ngIf="utility.percentOut !== undefined; else unavailable">
                                    <span>{{ utility.percentOut | percent : '1.2-2' }}</span>
                                    <img
                                        class="date-hover"
                                        src="dist/browser/images/icons/calendar.svg"
                                        alt="calendar"
                                        matTooltipShowDelay="250"
                                        #percentTooltip="matTooltip"
                                        [matTooltip]="utility.lastReportedOutageRunStartTime | momentDate"
                                        matTooltipPosition="above" />
                                </ng-container>

                                <ng-template #unavailable> Not Available</ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalCustomers">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Total Customers</span>
                                <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td class="right-cell" mat-cell *matCellDef="let utility">
                            <div>
                                <span class="ellipsis">{{ utility.coveredCustomers | eagleiNumber }} </span>
                                <span class="county-customer"> ({{ utility.coveredCustomersType | titlecase }})</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastHourOutage">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Last Hour Max Outage</span>
                                <eaglei-table-sort-header [columnName]="'lastHourOutage'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let utility"
                            (click)="hourTooltip.toggle()"
                            [style.color]="utility.maxOutage1HasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <span class="ellipsis">{{ utility.maxOutage1 | eagleiNumber }} </span>
                                <img
                                    class="date-hover"
                                    src="dist/browser/images/icons/calendar.svg"
                                    alt="calendar"
                                    [matTooltip]="utility.maxOutage1RunStartTime | momentDate"
                                    matTooltipPosition="above"
                                    matTooltipShowDelay="250"
                                    #hourTooltip="matTooltip" />
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastDayOutage">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Last Day Max Outage</span>
                                <eaglei-table-sort-header [columnName]="'lastDayOutage'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let utility"
                            (click)="dayTooltip.toggle()"
                            [style.color]="utility.maxOutage24HasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <span class="ellipsis">{{ utility.maxOutage24 | eagleiNumber }} </span>
                                <img
                                    class="date-hover"
                                    src="dist/browser/images/icons/calendar.svg"
                                    alt="calendar"
                                    [matTooltip]="utility.maxOutage24RunStartTime | momentDate"
                                    matTooltipPosition="above"
                                    matTooltipShowDelay="250"
                                    #dayTooltip="matTooltip" />
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
