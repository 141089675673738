
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import { GenRoleDefinition } from './GenRoleDefinition';
import moment from 'moment';

export class GenCreateTrainingUsersParam {

    expirationDate: moment.Moment;
    numUsers: number;
    prefix: string;
    roles: GenRoleDefinition[];

    constructor();
    constructor( jsonObj: any );
    constructor( genCreateTrainingUsersParam: GenCreateTrainingUsersParam);

    constructor( input?: GenCreateTrainingUsersParam ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.expirationDate && typeof input.expirationDate === 'string' ) {
                this.expirationDate = moment(input.expirationDate).utc();
            } else {
                this.expirationDate = input.expirationDate;
            }
            this.numUsers = input.numUsers; // int [number]
            this.prefix = input.prefix; // string [string]
            if ( input.roles && input.roles.map ) {
                this.roles = input.roles.map( ( x: GenRoleDefinition ) => {
                    return (typeof x === 'string') ? GenRoleDefinition.forName(<string> x) : <any> x; // GenRoleDefinition
                } );
            } else {
                this.roles = input.roles; // GenRoleDefinition
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenCreateTrainingUsersParam[] {
        if (arr && arr.map) {
          return arr.map(a => new GenCreateTrainingUsersParam(a));
        } else {
          return [];
        }
    }
}

