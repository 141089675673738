import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutocompleteComponent} from '../../../../../shared/components/autocomplete/autocomplete.component';
import {ReportGroup} from '../../classes/report-group';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {RoleDefinitionPipe} from '../../../../../shared/pipes/role-definition.pipe';
import {filter} from 'rxjs/operators';
import {ConfirmationComponent} from '../../../../../shared/modals/confirmation/confirmation.component';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

@Component({
    selector: 'eaglei-report-management',
    templateUrl: './report-management.component.html',
    styleUrls: ['../reports.scss', './report-management.component.scss'],
})
export class ReportManagementComponent implements OnInit {
    // DOM Elements
    @ViewChild('edit', {static: true}) edit: MatSidenav;
    @ViewChild('autocomplete', {static: true}) autocomplete: AutocompleteComponent;

    // Report Arrays Properties
    public reportGroups: ReportGroup[] = [];
    public filteredGroups: ReportGroup[] = [];
    public activeReportGroups: ReportGroup[] = [];
    public filteredReports: Report[] = [];
    private allReports: Report[] = [];

    // Filter Properties
    public filteredGroupId: number;
    private searchText: string = '';
    public sorted: boolean;
    public hasSearchText: boolean;

    // Role Properties
    private readonly rolePipe = new RoleDefinitionPipe();
    public roles: GenRoleDefinition[] = GenRoleDefinition.values().filter((role) => {
        return [GenRoleDefinition.ROLE_RESETTING_PASSWORD, GenRoleDefinition.ROLE_APPROVED_USER].indexOf(role) === -1;
    });

    // Tmp Report Properties
    private selectedReportName: string | undefined;
    public tmpReport: Report = new Report();
    public selectedGroup: ReportGroup;
    public tmpReportGroupId: number;

    // Form Control Properties
    public reportNameControl: UntypedFormControl;
    private reportNames: string[] = [];
    public attrGroup: UntypedFormGroup;
    public attributionURLs: string[] = [''];

    constructor(private reportService: ReportService, private popup: MatSnackBar, private dialog: MatDialog) {
        this.reportNameControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(120), this.reportNameValidator()]);

        this.attributionURLs = [];
        this.attrGroup = new UntypedFormGroup({});
    }

    public ngOnInit(): void {
        this.reportService.getAllReports(true).subscribe((res) => {
            this.reportGroups = res;
            this.allReports = this.reportGroups.reduce((prev, cur) => prev.concat(cur.dataProviders), []);
            this.filteredReports = this.allReports;
            this.getActiveReportGroups();
            this.sortReports(true);
        });

        this.autocomplete.displayFunction = this.displayAutoComplete.bind(this);
        this.autocomplete.filterFunction = this.filterAutoComplete.bind(this);

        this.roles.sort();
    }

    // Filter methods
    /**
     * Searches the Report titles for any that contain the search string
     * @param text The text to be found in the report titles
     */
    public searchReports(text: string): void {
        this.hasSearchText = !!text;
        this.applyFilters(text);
    }

    /**
     * Filters the reports to just the selected group
     * @param groupId The Id of the group to be filtered to
     */
    public filterToGroup(groupId: number): void {
        this.filteredGroupId = this.filteredGroupId === groupId ? undefined : groupId;
        this.applyFilters();
    }

    /**
     * Sorts the reports, when the sort flag is true it will sort alphabetically, otherwise it used the group ordering
     * @param bypassCheck if true, the sort flag will not be updated
     */
    public sortReports(bypassCheck: boolean = false): void {
        if (!bypassCheck) {
            this.sorted = !this.sorted;
        }

        this.applyFilters();
    }

    /**
     * Applies the filters to the list of reports returning the filtered list of reports and groups
     * @param text optional: the text to be found in report titles
     */
    private applyFilters(text?: string): void {
        this.searchText = text === undefined ? this.searchText : text;

        this.filteredReports = this.allReports.filter((report) => {
            const textCheck = report.displayName.toLowerCase().includes(this.searchText.toLowerCase().trim());
            const groupCheck = this.filteredGroupId === undefined ? true : report.dataProviderGroupId === this.filteredGroupId;
            return textCheck && groupCheck;
        });

        // noinspection DuplicatedCode
        if (this.sorted) {
            this.filteredReports.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
        } else {
            this.filteredReports.sort((a, b) => {
                const aGroup = this.reportGroups.find((g) => g.id === a.dataProviderGroupId);
                const bGroup = this.reportGroups.find((g) => g.id === b.dataProviderGroupId);

                if (aGroup.ordering > bGroup.ordering) {
                    return 1;
                } else if (aGroup.ordering < bGroup.ordering) {
                    return -1;
                } else {
                    return a.ordering > b.ordering ? 1 : -1;
                }
            });
        }

        this.filteredGroups = [];
        this.activeReportGroups.forEach((group) => {
            const reportCheck = this.filteredReports.filter((r) => r.dataProviderGroupId === group.id);

            if (reportCheck.length > 0) {
                group.dataProviders = [...reportCheck];
                this.filteredGroups.push(group);
            }
        });
    }

    // Autocomplete Methods
    /**
     * Display function for the Autocomplete component
     * @param role The role to be displayed
     */
    private displayAutoComplete(role: GenRoleDefinition): string {
        return role ? this.rolePipe.transform(role) : '';
    }

    /**
     * Filter function for the Autocomplete component
     * @param role The role checked to be filtered
     * @param textFilter The text to filter to
     */
    private filterAutoComplete(role: GenRoleDefinition, textFilter: string): boolean {
        return this.rolePipe.transform(role).toLowerCase().includes(textFilter.toLowerCase());
    }

    // Edit Report Methods
    /**
     * Opens the drawer to edit a selected report putting it in a temporay report to edit
     * @param report The report opened to be edited
     * @param group The orginal group of the report selected
     */
    public editReport(report: Report, group: ReportGroup): void {
        this.attributionURLs = report.attributionUrl.split(',');
        this.selectedReportName = report.displayName.toLowerCase();
        this.tmpReport = new Report(report);
        this.autocomplete.selectedElements = this.tmpReport.roles;
        this.selectedGroup = group;
        this.tmpReportGroupId = group.id;
        this.attrGroup = new UntypedFormGroup({});
        this.attributionURLs.forEach((val: string, index: number) => this.addAttributionControl(index));

        this.edit.toggle().then(() => (this.reportNames = this.getAllReportNames()));
    }

    /**
     * Saves the temporary report as the old selected report
     */
    public updateReport(): void {
        this.tmpReport.roles = this.autocomplete.selectedElements;
        this.tmpReport.dataProviderGroupId = this.selectedGroup.id;
        this.tmpReport.attributionUrl = this.attributionURLs.join();
        this.tmpReport.attributionTitle = this.tmpReport.attributionTitle.replace(/{TRADEMARK}/g, '&trade;');

        this.reportService.updateReport(this.tmpReport).subscribe(
            () => {
                this.popup.open('Report Update Successful', '', {duration: 1000, panelClass: ['success']});
                this.updateReportGroup(true);
                this.edit.toggle().then(() => (this.selectedReportName = undefined));
            },
            (error: string) => {
                console.error(error);
                this.popup.open('Report Update Failed', 'OK', {panelClass: ['failure']});
            }
        );
    }

    /**
     * Deletes the selected report after confirming the action
     */
    public deleteReport(): void {
        this.dialog
            .open(ConfirmationComponent, {data: {message: 'Are you sure you want to delete this report?'}})
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                this.reportService.deleteReport(this.tmpReport).subscribe(
                    () => {
                        this.popup.open('Report Update Successful', '', {duration: 1000, panelClass: ['success']});
                        this.updateReportGroup(false);
                        this.edit.toggle().then(() => (this.selectedReportName = undefined));
                    },
                    (error: string) => {
                        console.error(error);
                        this.popup.open('Report Update Failed', 'OK', {panelClass: ['failure']});
                    }
                );
            });
    }

    /**
     * Updates the report groups removing or replacing the selected report
     * @param replace If true, replaces the report in the report group
     */
    private updateReportGroup(replace: boolean): void {
        if (this.tmpReport.dataProviderGroupId !== this.tmpReportGroupId) {
            const prevGroupIndex = this.reportGroups.findIndex((group) => group.id === this.tmpReportGroupId);
            const changeLayerIndex = this.reportGroups[prevGroupIndex].dataProviders.findIndex((layer) => layer.id === this.tmpReport.id);
            const newGroupIndex = this.reportGroups.findIndex((group) => group.id === this.tmpReport.dataProviderGroupId);

            this.reportGroups[prevGroupIndex].dataProviders.splice(changeLayerIndex, 1);
            this.reportGroups[newGroupIndex].dataProviders.push(this.tmpReport);
        }

        const groupIndex = this.reportGroups.findIndex((group) => group.id === this.selectedGroup.id);
        const reportIndex = this.reportGroups[groupIndex].dataProviders.findIndex((layer) => layer.id === this.tmpReport.id);
        replace
            ? this.reportGroups[groupIndex].dataProviders.splice(reportIndex, 1, this.tmpReport)
            : this.reportGroups[groupIndex].dataProviders.splice(reportIndex, 1);

        this.getActiveReportGroups();
    }

    // Form Control Validators
    /**
     * Validator for the report names
     */
    private reportNameValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value || control.value.toLowerCase() === this.selectedReportName) {
                return null;
            }

            const name = (control.value as string).trim().toLowerCase();
            if (name.length === 0) {
                return {onlySpace: 'Report name can not only be a space'};
            } else if (this.reportNames.indexOf(name) !== -1) {
                return {nameExists: 'Report name already exists'};
            }
            return null;
        };
    }

    /**
     * Validator using a pattern
     * @param regex The expression to test the control against
     */
    private patternValidator(regex: RegExp): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }
            if (!regex.test(control.value)) {
                return {pattern: 'URL must begin with http:// or https://'};
            }
            return null;
        };
    }

    // Attribution methods
    /**
     * Updates an attribution at a given index
     * @param event The attribution to be updated to
     * @param index The index of the attribution
     */
    public updateAttr(event: any, index: number): void {
        this.attributionURLs[index] = event;
    }

    /**
     * Adds a new attribution url to the list for the selected report
     */
    public addAttribution(): void {
        if (this.attrGroup.invalid) {
            return;
        }

        this.attributionURLs.push('');
        this.addAttributionControl(this.attributionURLs.length - 1);

        this.attrGroup.updateValueAndValidity();
    }

    /**
     * Removes the attribution at a given index
     * @param index The index of the attribution to be removed
     */
    public removeAttribution(index: number): void {
        if (this.attributionURLs.length - 1 !== index) {
            this.attributionURLs.push(...this.attributionURLs.splice(index, 1));
        }

        this.removeAttributionControl(this.attributionURLs.length - 1);
        this.attributionURLs.pop();
    }

    /**
     * Returns the index of the attribution in order to track which one is begin changed
     * The attribution isn't used but is needed for the method to hook into the DOM
     * @param index The index of the attribution
     * @param attribution The attribution text
     */
    public trackAttribution(index: number, attribution: string): number {
        return index;
    }

    /**
     * Adds a new attribution control using the index to name the control
     * @param index The index of the new attribution
     */
    private addAttributionControl(index: number): void {
        const control = new UntypedFormControl('', [Validators.required, this.patternValidator(new RegExp('^(http://|https://).*', 'i'))]);

        this.attrGroup.addControl(`attribution${index}`, control);
    }

    /**
     * Removes the attribution control using the index to find the correct name of the control
     * @param index The index of the attribution
     */
    private removeAttributionControl(index: number): void {
        this.attrGroup.removeControl(`attribution${index}`);
    }

    /**
     * Gets the form control of an attribution using the index of the attribution
     * @param index The index of the attribution
     */
    public getFormControl(index: number): AbstractControl {
        return this.attrGroup.get(`attribution${index}`);
    }

    // Utility Methods
    /**
     * Gets active groups with report data providers and sorts them alphabetically
     */
    private getActiveReportGroups(): any {
        this.activeReportGroups = this.reportGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    /**
     * Gets all report names from each report group
     */
    private getAllReportNames(): string[] {
        return this.reportGroups
            .map((group) => {
                return group.dataProviders.map((report) => report.displayName.toLowerCase());
            })
            .reduce((prev, cur) => prev.concat(cur), []);
    }

    /**
     * Returns if the report is enabled or disabled
     * @param report The report to be looked at
     */
    public isEnabled(report: Report): boolean {
        return report.active;
    }
}
