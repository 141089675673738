import {Component, inject, signal, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {debounceTime, map, take, tap} from 'rxjs/operators';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {EmPowerSource} from '../../../layer/sources/emPower-source';
import {MapOptions} from '../../../map/classes/map-options';
import {State} from '../../../outage/classes/state';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {FormControl, FormGroup} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {HssData} from '../../classes/HssData';
import {AggregationLevelPipe} from '../../../../../shared/pipes/aggregation-level.pipe';
import {FileDownload} from '../../../../classes/file-download';
import {StateNamePipe} from '../../../../../shared/pipes/state-name.pipe';

@Component({
    selector: 'eaglei-empower-report',
    templateUrl: './empower-report.component.html',
    styleUrls: ['../reports.scss', './empower-report.component.scss'],
})
export class EmpowerReportComponent extends Report<HssData> {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private readonly STATE_COLUMNS = ['name', 'beneficiaries', 'electricity'];
    private readonly COUNTY_COLUMNS = ['name', 'state', 'beneficiaries', 'electricity'];
    private readonly ZIP_COLUMNS = ['name', 'county', 'state', 'beneficiaries', 'electricity'];

    private layerService = inject(LayerService);
    public reportService = inject(ReportService);

    public columnNames = this.STATE_COLUMNS;

    protected loadingMask$ = signal(false);

    protected filterGroup = new FormGroup({
        aggregation: new FormControl<GenOutageAggregationLevel>(undefined),
        states: new FormControl<State[]>([]),
    });

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.utility, GenOutageAggregationLevel.fema].includes(l)
    );

    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: EmPowerSource;

    public readonly dependentLegend = [
        {zip: '0', county: '0', state: '24 - 20,553'},
        {zip: '1 - 133', county: '1 - 255', state: '20,554 - 45,704'},
        {zip: '134 - 237', county: '256 - 976', state: '45,705 - 77,199'},
        {zip: '238 - 396', county: '977 - 7,571', state: '77,200 - 122,538'},
        {zip: '397 - 1,366', county: '> 7,572', state: '> 122,539'},
    ];
    private aggregationPipe = new AggregationLevelPipe();
    private statePipe = new StateNamePipe();

    constructor() {
        super();

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };

        this.reportService
            .getReportData()
            .pipe(take(1))
            .subscribe((r) => {
                this.initializeReportInfo(r);
            });

        this.filterGroup.valueChanges.pipe(debounceTime(100), takeUntilDestroyed()).subscribe((value) => {
            switch (value.aggregation) {
                case GenOutageAggregationLevel.state:
                    this.columnNames = this.STATE_COLUMNS;
                    break;
                case GenOutageAggregationLevel.county:
                    this.columnNames = this.COUNTY_COLUMNS;
                    break;
                case GenOutageAggregationLevel.zip:
                    this.columnNames = this.ZIP_COLUMNS;
                    break;
            }

            const regionsSelected = new Set(value.states.map((state) => state.dotregion));
            const countyCheck = regionsSelected.size > 1 && value.aggregation === GenOutageAggregationLevel.county;
            const zipCheck = value.states.length > 1 && value.aggregation === GenOutageAggregationLevel.zip;

            if (countyCheck || zipCheck) {
                this.loadingMask$.update(() => {
                    return countyCheck || zipCheck;
                });
                return;
            }

            this.getData();
        });

        this.getUserPreferences();

        this.layerService
            .getLayerByHandle('emPower')
            .pipe(take(1))
            .subscribe((layer) => {
                this.mapLayer = layer;
                this.layerSource = new EmPowerSource(layer);
            });
    }

    private getData(): void {
        const url = this.layerSource.buildUrl(this.filterGroup.value.aggregation, this.filterGroup.value.states);
        this.mapLayer?.startLoading();
        this.loadingMask$.set(false);

        HttpInterceptorService.clearInterceptor(this.interceptorKey);
        HttpInterceptorService.pendingRequests[this.interceptorKey] = this.layerService
            .getJsonFromUrl(url, true)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.interceptorKey);
                    this.mapLayer.endLoading();
                }),
                map((fc) => {
                    return fc.features.map((feature: {properties: HssData}) => {
                        feature.properties = new HssData(feature.properties);
                        return feature;
                    });
                })
            )
            .subscribe((f) => {
                this.layerSource.processFeatures(f, this.filterGroup.value.aggregation);
                this.layerSource.changeOpacity(0.8);
                this.layerSource.removeFromMap();
                this.layerSource.addToMap();

                const zoomStates = this.filterGroup.value.states.map((s) => s.abbreviation);
                if (zoomStates.length > 1 && (!zoomStates.includes('AK') || !zoomStates.includes('HI'))) {
                    this.layerSource.fitToFeatures();
                } else if (zoomStates.length <= 1) {
                    this.layerSource.fitToFeatures();
                }

                this.initData(f.map((feature) => feature.properties));
            });
    }

    private initData(data: HssData[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<any>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    private dataAccessor(data: HssData, column: string): string | number {
        switch (column) {
            case 'name':
                return data.name.toLowerCase();
            case 'state':
                return data.state.toLowerCase();
            case 'county':
                return data.county.toLowerCase();
            case 'beneficiaries':
                return data.beneficiaries;
            case 'electricity':
                return data.energyDependant;
            default:
                return '';
        }
    }

    private filterPredicate(data: HssData): boolean {
        return this.filterGroup.value.states.find((s) => s.abbreviation === data.state) !== undefined;
    }

    private getUserPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();

        this.filterGroup.patchValue({
            aggregation: preferences.getOutageAggregationLevel(),
            states: preferences.getStates(),
        });
    }

    public filterStates(event: State[]): void {
        this.filterGroup.patchValue({
            states: event,
        });
    }

    public exportTable(): void {
        let data = '';
        const filterValues = this.filterGroup.value;

        const headerColumns = [
            this.aggregationPipe.transform(filterValues.aggregation),
            filterValues.aggregation === GenOutageAggregationLevel.zip ? 'County' : undefined,
            filterValues.aggregation !== GenOutageAggregationLevel.state ? 'State' : undefined,
            'Beneficiaries',
            'Electricity-Dependent Beneficiaries',
        ];

        data += headerColumns.filter((v) => !!v).join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(d.name),
                filterValues.aggregation === GenOutageAggregationLevel.zip ? FileDownload.formatCsvCell(d.county) : undefined,
                filterValues.aggregation !== GenOutageAggregationLevel.state
                    ? FileDownload.formatCsvCell(this.statePipe.transform(d.state))
                    : undefined,
                FileDownload.formatCsvCell(Report.numberPipe.transform(d.beneficiaries)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(d.energyDependant)),
            ].filter((v) => !!v);

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('emPower', data, this.attributionUrl);
    }
}
