import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Report} from '../../../../app/modules/report/classes/report';
import {RefineryStatusUnit} from '../../classes/refinery-status-unit';

@Component({
    selector: 'eaglei-oil-refinery-status-table',
    templateUrl: './oil-refinery-status-table.component.html',
    styleUrls: ['./oil-refinery-status-table.component.scss'],
})
export class OilRefineryStatusTableComponent extends Report<RefineryStatusUnit> implements AfterViewInit {
    @Input() units: RefineryStatusUnit[];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columns: string[] = [
        'status',
        'id',
        'name',
        'processing_class',
        'category',
        'outage_type',
        'capacity',
        'measurement_date',
    ];

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        this.initializeTable(this.units);
    }

    /**
     * Sets up the data to be down in the table, if the table already exist, the table source data is updated, otherwise
     * the table source is created and the sorting logic and paginator are created.
     * @param data A list of RefineryStatusUnits objects to be shown in the table
     * @private
     */
    private initializeTable(data: RefineryStatusUnit[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<RefineryStatusUnit>(data);
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    // noinspection JSMethodCanBeStatic
    private sortingDataAccessor(data: RefineryStatusUnit, columnName: string): string | number {
        switch (columnName) {
            case 'id':
                return data.unitId.toLowerCase();
            case 'name':
                return data.name.toLowerCase();
            case 'processing_class':
                return data.processingClass.toLowerCase();
            case 'category':
                return data.category.toLowerCase();
            case 'outage_type':
                return data.outageType.toLowerCase();
            case 'unit_capacity':
                return data.capacity;
            case 'measurement_date':
                return data.measurementDate.valueOf();
        }
    }
}
