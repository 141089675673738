import {Component, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import moment from 'moment';
import {RefineryStatus} from '../../classes/refinery-status';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {ReportFilter} from '../../enums/report-filter.enum';
import {Router} from '@angular/router';
import {OngService} from '../../../../services/ong.service';
import {FileDownload} from '../../../../classes/file-download';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'eaglei-refinery-status-report',
    templateUrl: './refinery-status-report.component.html',
    styleUrls: ['../reports.scss', './refinery-status-report.component.scss'],
})
export class RefineryStatusReportComponent extends Report<RefineryStatus> implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['expand-icon', 'status', 'name', 'capacity', 'impacted_capacity', 'percent_impacted'];

    public selectedStatus: 'all' | 'online' | 'impacted' | 'offline' = 'all';
    public startDate: moment.Moment = ApplicationConfig.roundMinute();
    public maxDate: moment.Moment = ApplicationConfig.roundMinute();

    public selectedRow: RefineryStatus;

    public filterGroup: FormGroup = new FormGroup({
        nameSearch: new FormControl<string>(''),
    });

    constructor(public reportService: ReportService, private router: Router, protected ongService: OngService) {
        super();
        const routeFilters: IReportNavigationConfig[] = this.router?.getCurrentNavigation()?.extras?.state?.filters;
        if (routeFilters) {
            const status: string = routeFilters.find((f) => f.type === ReportFilter.PRIORITY).value as string;
            const startDate: string = routeFilters.find((f) => f.type === ReportFilter.START_DATE).value as string;
            this.selectedStatus = status as any;
            this.startDate = moment(startDate);
            this.maxDate = this.startDate.clone();
        }

        this.filterGroup
            .get('nameSearch')
            .valueChanges.pipe(takeUntilDestroyed())
            .subscribe((val: string) => {
                this.dataSource.filter = val.trim().length > 0 ? val : ' ';
            });
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        this.getRefineryData();
    }

    public getRefineryData(): void {
        this.ongService.getRefineryStatus(this.startDate).subscribe((res) => {
            this.initData(res.features.map((r) => new RefineryStatus(r.properties)));
        });
    }

    private initData(data: RefineryStatus[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<any>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }

        this.dataSource.filter = ' ';
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: RefineryStatus, column: string): string | number {
        switch (column) {
            case 'name':
                return data.name.toLowerCase();
            case 'percent_impacted':
                return data.offline_capacity / data.total_capacity;
            default:
                return '';
        }
    }

    private filterPredicate(data: RefineryStatus, searchText: string): boolean {
        let statusCheck = false;

        switch (this.selectedStatus) {
            case 'online':
                statusCheck = data.status === 'Online';
                break;
            case 'offline':
                statusCheck = data.status === 'Offline';
                break;
            case 'impacted':
                statusCheck = data.status === 'Reduced Capacity';
                break;
            default:
                statusCheck = true;
                break;
        }

        return statusCheck && data.name?.toLowerCase().includes(searchText.toLowerCase());
    }

    public changeSelectedStatus(event: MatSelectChange): void {
        this.selectedStatus = event.value;

        this.dataSource.filter = this.dataSource.filter.length > 0 ? this.dataSource.filter : ' ';
    }

    public toggleExpansion(row: RefineryStatus): void {
        row.expanded = !row.expanded;
    }

    public exportTable(): void {
        let data = ['Status', 'Name', 'Capacity (bpd)', 'Impacted Capacity (bpd)', 'Percent Impacted', '\n'].join();
        const expandedColumns = [
            'Unit Status',
            'Unit ID',
            'Unit Name',
            'Processing Class',
            'Category',
            'Outage Type',
            'Unit Capacity (bpd)',
            'Measurement Date',
            '\n',
        ].join();

        // console.log(this.dataSource.filteredData);

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            const values = [
                FileDownload.formatCsvCell(val.status),
                FileDownload.formatCsvCell(val.name),
                FileDownload.formatCsvCell(val.total_capacity),
                FileDownload.formatCsvCell(val.offline_capacity),
                FileDownload.formatCsvCell(`${((val.offline_capacity / val.total_capacity) * 100).toFixed(2)} %`),
            ];

            data += values.join();
            data += '\n';
        });

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data += `\n\n${FileDownload.formatCsvCell(val.name)}\n`;

            data += expandedColumns;

            val.units.forEach((unit) => {
                const values = [
                    FileDownload.formatCsvCell(unit.online ? 'Online' : 'Offline'),
                    FileDownload.formatCsvCell(unit.unitId),
                    FileDownload.formatCsvCell(unit.name),
                    FileDownload.formatCsvCell(unit.processingClass),
                    FileDownload.formatCsvCell(unit.category),
                    FileDownload.formatCsvCell(unit.outageType),
                    FileDownload.formatCsvCell(unit.capacity),
                    FileDownload.formatCsvCell(unit.measurementDate.format('M/D/YYYY')),
                ];

                data += values.join();
                data += '\n';
            });
        });

        FileDownload.downloadCSV('refineryStatusReport', data, this.attributionUrl);
    }

    updateDateRange(date: moment.Moment) {
        this.startDate = date;
        this.getRefineryData();
    }

    /**
     * Sets the expanded row to the row of the table that was clicked. If the row
     * was already expanded, the row will be closed.
     * @param row The table row that was clicked
     */
    public toggleExpandedRow(row: RefineryStatus): void {
        const isRowSelected = this.selectedRow?.facility_id === row.facility_id;
        this.selectedRow = isRowSelected ? undefined : row;
    }
}
