<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select" [class.active]="isSearchTextActive()">
            <mat-label *ngIf="isSearchTextActive()">Search Utilities</mat-label>
            <!--suppress TypeScriptUnresolvedVariable -->
            <input matInput placeholder="Search Utilities" (keyup)="filterUtilities($event.target.value)" />
            <fa-icon matSuffix class="icon input-search-icon" icon="search"></fa-icon>
        </mat-form-field>

        <eaglei-location-filter
            [newDesign]="true"
            (locations)="changeSelectedLocations($event)"
            [defaultSelection]="selectedStates"></eaglei-location-filter>

        <eaglei-date-range [ignoreMinDate]="true" [start]="startDate" [end]="endDate" [newDesign]="true" (change)="changeDateRange($event)">
        </eaglei-date-range>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="filter-chips">
    <span class="filter-text">Filters: </span>
    <eaglei-filter-chip-list [states]="selectedStates" (change)="changeSelectedLocations($event)"></eaglei-filter-chip-list>
</div>

<!--Report Layout-->
<div class="report-data">
    <ng-container [ngTemplateOutlet]="lineChart"></ng-container>

    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!--Chart-->
<ng-template #lineChart>
    <mat-card class="chart">
        <mat-card-subtitle [class.invisible]="mask.show">
            <div class="chart-legend">
                <svg width="300" height="20" #chartLegend>
                    <rect width="15" height="15" x="5" y="3" rx="5" ry="5" [attr.fill]="outageLineColor"></rect>
                    <text x="25" y="15" style="font-size: 12px">EAGLE-I&trade; Outage Data</text>

                    <rect width="15" height="15" x="175" y="3" rx="5" ry="5" [attr.fill]="overrideLineColor"></rect>
                    <text x="195" y="15" style="font-size: 12px">EOC Override Data</text>
                </svg>
            </div>
        </mat-card-subtitle>
        <mat-card-content [class.invisible]="mask.show">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="mask.show">
            <span class="mask-message">
                <span>{{ getMaskText() }}</span>
            </span>
        </div>
    </mat-card>
</ng-template>

<!--Table-->
<ng-template #reportTable>
    <mat-card [class.invisible]="dataSource && dataSource.filteredData.length === 0">
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="overrideCount"
                    matSortDirection="desc">
                    <ng-container matColumnDef="utilityName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Utility Name
                            <eaglei-table-sort-header [columnName]="'utilityName'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.utilityName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        `
                        <td mat-cell *matCellDef="let info">{{ info.state }}</td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.countyName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="outageCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Outage Count
                            <eaglei-table-sort-header [columnName]="'outageCount'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.outageCount | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="overrideCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Override Count
                            <eaglei-table-sort-header [columnName]="'overrideCount'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.overrideCount | number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="runStartTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Run Start Time
                            <eaglei-table-sort-header [columnName]="'runStartTime'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.runStartTime | momentDate }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        class="clickable"
                        (click)="openComparisonModal(row)"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
        <div class="auto-mask hide-mask" *ngIf="dataSource && dataSource.filteredData.length === 0">
            <span class="mask-message">
                <span>No Utilities Matching Filters</span>
            </span>
        </div>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
