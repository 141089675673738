<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table
                #materialTable
                mat-table
                [dataSource]="dataSource"
                matSort
                [matSortDisableClear]="true"
                matSortActive="name"
                matSortDirection="desc">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Unit Status</th>
                    <td mat-cell *matCellDef="let info">
                        <div class="status-icon small" [class.offline]="!info.online">
                            <fa-icon [icon]="info.online ? 'check' : 'ban'"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Unit ID
                        <eaglei-table-sort-header [columnName]="'id'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.unitId }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Unit Name
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.name }}</td>
                </ng-container>

                <ng-container matColumnDef="processing_class">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Processing Class
                        <eaglei-table-sort-header [columnName]="'processing_class'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.processingClass }}</td>
                </ng-container>

                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Category
                        <eaglei-table-sort-header [columnName]="'category'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.category }}</td>
                </ng-container>

                <ng-container matColumnDef="outage_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Outage Type
                        <eaglei-table-sort-header [columnName]="'outage_type'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.outageType }}</td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Capacity
                        <eaglei-table-sort-header [columnName]="'capacity'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.capacity | number }}</td>
                </ng-container>

                <ng-container matColumnDef="measurement_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Measurement Date
                        <eaglei-table-sort-header [columnName]="'measurement_date'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.measurementDate | momentDate }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
            </table>

            <mat-paginator pageSize="7" hidePageSize showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
