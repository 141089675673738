import {Component, inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../../classes/base-modal';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GenRoleDefinition} from 'frontend/generated/serverModels/GenRoleDefinition';
import {ManagementService} from '../../../management/services/management.service';
import {GenTrainingUser} from 'frontend/generated/serverModels/GenTrainingUser';
import {LoadingMaskOptions} from 'frontend/src/app/classes/loading-mask-options';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {GenCreateTrainingUsersParam} from 'frontend/generated/serverModels/GenCreateTrainingUsersParam';
import moment from 'moment';

@Component({
    selector: 'eaglei-create-training-users-modal',
    templateUrl: './create-training-users-modal.component.html',
    styleUrls: ['./create-training-users-modal.component.scss'],
})
export class CreateTrainingUsersModalComponent extends BaseModal implements OnInit {
    private dialogRef = inject(MatDialogRef<CreateTrainingUsersModalComponent>);
    private managementService = inject(ManagementService);

    public trainingUsersGroup: UntypedFormGroup;
    public readonly allRoles: GenRoleDefinition[] = [];
    public generatedUsers: GenTrainingUser[] = [];
    public readonly columnNames = ['username', 'password', 'apiKey'];

    public minDate = ApplicationConfig.roundMinute().toDate();
    public startDate = ApplicationConfig.roundMinute().add(1, 'week').toDate();

    constructor() {
        super();
        this.allRoles = GenRoleDefinition.values().sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    ngOnInit(): void {
        this.trainingUsersGroup = new UntypedFormGroup({
            numUsers: new UntypedFormControl('', {
                updateOn: 'change',
                validators: [Validators.required, Validators.min(1), Validators.max(10)],
            }),
            prefix: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.pattern(/^\S+$/)]}),
            roles: new UntypedFormControl([]),
            expirationDate: new FormControl<Date>(this.startDate),
        });
    }

    // Mask Methods
    /**
     * Configures and displays the loading mask
     */
    public showMask(): void {
        const config = new LoadingMaskOptions();
        config.showMask = true;
        ApplicationConfig.pageMask.next(config);
    }

    public hideMask(): void {
        const config = ApplicationConfig.pageMask.getValue();
        config.showMask = false;
        ApplicationConfig.pageMask.next(config);
    }

    /**
     * Calls the API to create training users
     */
    public createTrainingUsers(): void {
        if (this.trainingUsersGroup.valid) {
            this.showMask();
            const params = new GenCreateTrainingUsersParam(this.trainingUsersGroup.getRawValue());
            params.expirationDate = moment(params.expirationDate).endOf('day');

            this.managementService.createTrainingUsers(params).subscribe((users) => {
                this.generatedUsers = users;
                this.hideMask();
            });
        }
    }

    /**
     * Closes the modal without sending any data.
     */
    public close(): void {
        this.dialogRef.close(this.generatedUsers.length !== 0 ? this.generatedUsers : undefined);
    }

    // From Parent class
    afterInit() {}
}
