<div class="dialog-header">
    <span>Maintenance Event Info</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<mat-dialog-content #content class="dialog-content">
    <p class="title">{{ maintenanceData.name }}</p>

    <div class="item-container">
        <div class="item">
            <span class="tiny-header">Start Date</span>
            <span class="val">{{ maintenanceData.start_date | momentDate }}</span>
        </div>

        <div class="item">
            <span class="tiny-header">End Date</span>
            <span class="val">{{ maintenanceData.end_date | momentDate }}</span>
        </div>

        <div class="item">
            <span class="tiny-header">Update Date</span>
            <span class="val">{{ maintenanceData.update_date | momentDate }}</span>
        </div>

        <div class="item">
            <span class="tiny-header">Priority</span>
            <span class="val">{{ maintenanceData.priority }}</span>
        </div>

        <div class="item">
            <span class="tiny-header">Quantity (mmbtu)</span>
            <span class="val">{{ maintenanceData.quantity_estimate_mmbtu | eagleiNumber }}</span>
        </div>
    </div>

    <div class="item">
        <span class="tiny-header">Subject</span>
        <span class="val">{{ maintenanceData.subject }}</span>
    </div>

    <div class="item">
        <span class="tiny-header">Description</span>
        <span class="val">{{ maintenanceData.impact_description }}</span>
    </div>

    <div class="item">
        <span class="tiny-header">Details</span>
        <span class="val">{{ maintenanceData.details || 'N/A' }}</span>
    </div>

    <div *ngIf="locations.length > 0" class="item">
        <span class="tiny-header">Locations</span>
        <span *ngFor="let location of locations" class="val">{{ location.locationName }}</span>
    </div>

    <div *ngIf="tariffs.length > 0" class="item">
        <span class="tiny-header">Tariff Zones</span>
        <span *ngFor="let tariff of tariffs" class="val">{{ tariff.tariffZoneName }}</span>
    </div>

    <div *ngIf="entities.length > 0" class="item">
        <span class="tiny-header">Other Entities</span>
        <span *ngFor="let entity of entities" class="val">{{ entity.entityName }} - {{ entity.entityType }}</span>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
