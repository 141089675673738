
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import moment from 'moment';

export class GenOutageData {

    customersOut: number;
    hasOverrideData: boolean;
    timeStamp: moment.Moment;

    constructor();
    constructor( jsonObj: any );
    constructor( genOutageData: GenOutageData);

    constructor( input?: GenOutageData ) {
        if ( input && typeof input !== 'number' ) {
            this.customersOut = input.customersOut; // int [number]
            this.hasOverrideData = input.hasOverrideData; // boolean [boolean]
            if ( input.timeStamp && typeof input.timeStamp === 'string' ) {
                this.timeStamp = moment(input.timeStamp).utc();
            } else {
                this.timeStamp = input.timeStamp;
            }
       }
    }

    static jsonResponseToList(arr: any[]): GenOutageData[] {
        if (arr && arr.map) {
          return arr.map(a => new GenOutageData(a));
        } else {
          return [];
        }
    }
}

