import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {GenUrbanNetFemaType} from '../../../../../../generated/serverModels/GenUrbanNetFemaType';
import {MatTableDataSource} from '@angular/material/table';
import {UrbannetEffect} from '../../../../../integrations/urbannet/classes/urbannet-effect';
import {FormControl} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {UrbannetEffectSource} from '../../../../../integrations/urbannet/classes/urbannet-effect-source';
import {debounceTime} from 'rxjs/operators';
import {LeafletFeature} from '../../../../modules/layer/classes/leaflet-feature';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalConfig} from '../../../../classes/modal-config';
import {UrbannetAssetInfoModalComponent} from '../../modals/urbannet-asset-info-modal/urbannet-asset-info-modal.component';
import {UrbannetService} from '../../../../../integrations/urbannet/services/urbannet.service';

@Component({
    selector: 'eaglei-affected-asset-table',
    templateUrl: './affected-asset-table.component.html',
    styleUrls: ['./affected-asset-table.component.scss'],
})
export class AffectedAssetTableComponent implements AfterViewInit, OnDestroy {
    @Input() affectedAssets: UrbannetEffect[];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public femaType = GenUrbanNetFemaType;
    public readonly columns: string[] = ['name', 'type', 'category', 'more-info'];
    public dataSource: MatTableDataSource<UrbannetEffect>;

    public typeControl: FormControl<GenUrbanNetFemaType[]>;
    public readonly typeOptions = [
        GenUrbanNetFemaType.ENERGY,
        GenUrbanNetFemaType.COMMUNICATIONS,
        GenUrbanNetFemaType.FOOD_WATER_SHELTER,
        GenUrbanNetFemaType.SAFETY_AND_SECURITY,
        GenUrbanNetFemaType.HEALTH_AND_MEDICAL,
        GenUrbanNetFemaType.TRANSPORTATION,
        GenUrbanNetFemaType.HAZARDOUS_MATERIAL,
        GenUrbanNetFemaType.OTHER,
    ];

    private effectLayer: UrbannetEffectSource = new UrbannetEffectSource();

    constructor(private urbannetService: UrbannetService, private dialog: MatDialog) {
        this.initializeControls();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.effectLayer.processFeatures(this.affectedAssets);
            this.effectLayer.addToMap();
            this.effectLayer.fitToFeatures();

            this.initializeTable(this.affectedAssets);
        }, 100);
    }

    ngOnDestroy() {
        this.effectLayer.removeFromMap();
        this.urbannetService.assetTableClosed$.next(undefined);
    }

    /**
     * Creates the form controls used by the table and sets up default values as well
     * as on change listeners
     * @private
     */
    private initializeControls(): void {
        this.typeControl = new FormControl(GenUrbanNetFemaType.values());

        this.typeControl.valueChanges.pipe(debounceTime(250)).subscribe(() => {
            this.dataSource.filter = ' ';

            const filteredIds = this.dataSource.filteredData.map((effect) => effect.id);
            const filteredFeatures = ((this.effectLayer.source.options as any).features as LeafletFeature<UrbannetEffect>[]).filter(
                (feature) => filteredIds.includes(feature.properties.id)
            );

            this.effectLayer.redraw(filteredFeatures);
        });
    }

    /**
     * Sets up the data to be down in the table, if the table already exist, the table source data is updated, otherwise
     * the table source is created and the sorting logic and paginator are created.
     * @param data A list of UrbannetEffect objects to be shown in the table
     * @private
     */
    private initializeTable(data: UrbannetEffect[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<UrbannetEffect>(data);
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    /**
     * Table filter method, Checks to see if a row is the same type as the type filer
     * @param data A row from the table.
     * @private
     */
    private filterPredicate(data: UrbannetEffect): boolean {
        return this.typeControl.value.includes(data.femaType);
    }

    /**
     * Callback function that opens a modal to display the parsed properties for a given UrbannetEffect
     * @param info A UrbannetEffect object to display the properties for.
     */
    public openAssetInfoModal(info: UrbannetEffect) {
        const config: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            data: {
                name: info.name,
                properties: info.parsedProperties,
            },
        };

        this.dialog.open(UrbannetAssetInfoModalComponent, config);
    }

    private sortingDataAccessor(data: UrbannetEffect, columnName: string) {
        switch (columnName) {
            case 'name':
                return data.name.toLowerCase();
            case 'type':
                return data.nodeId.toLowerCase();
            case 'category':
                return data.femaType.toString().toLowerCase();
        }
    }
}
