<form [formGroup]="filterGroup" class="filters filter-bar">
    <mat-button-toggle-group formControlName="aggregation">
        <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level">{{ level | aggregationLevel }} </mat-button-toggle>
    </mat-button-toggle-group>

    <eaglei-location-filter (locations)="filterStates($event)" [defaultSelection]="filterGroup.value.states"></eaglei-location-filter>

    <button mat-icon-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</form>

<div class="report-data">
    <div class="map-section">
        <ng-container [ngTemplateOutlet]="map"></ng-container>
    </div>

    <div class="table-section">
        <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
    </div>
</div>

<ng-template #map>
    <mat-card class="map">
        <mat-card-content>
            <eaglei-leaflet-map [mapOptions]="mapOptions" [class.invisible]="loadingMask$()"></eaglei-leaflet-map>
            <div class="auto-mask" *ngIf="loadingMask$()">
                <span class="mask-message">{{
                    filterGroup.value.aggregation.toString() === 'county' ? layerSource.COUNTY_MASK_TEXT : layerSource.ZIP_MASK_TEXT
                }}</span>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div class="footer-legend">
                <span class="legend-label">Electricity-Dependent Scale: </span>
                <div *ngFor="let ele of dependentLegend; let i = index" class="legend-item">
                    <div [class]="'area-color-' + i" class="legend-box"></div>
                    <span class="sml-text"> {{ ele[filterGroup.value.aggregation.toString()] }}</span>
                </div>
            </div>
        </mat-card-footer>
    </mat-card>
</ng-template>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container" [class.invisible]="loadingMask$()">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ filterGroup?.value.aggregation | aggregationLevel }}
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span> {{ info.name | stateName }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span> {{ info.state | stateName }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span> {{ info.county }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="beneficiaries">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Beneficiaries
                            <eaglei-table-sort-header [columnName]="'beneficiaries'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span>{{ info.beneficiaries | eagleiNumber }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="electricity">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span>Electricity-Dependent Beneficiaries</span>
                            <eaglei-table-sort-header [columnName]="'electricity'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.energyDependant | eagleiNumber }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution" [class.invisible]="loadingMask$()">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
