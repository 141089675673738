import {Component, Inject, OnInit} from '@angular/core';
import {BaseModal} from '../../../../classes/base-modal';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UrbannetReport} from '../../../../../integrations/urbannet/classes/urbannet-report';

interface Metadata {
    event_id: number;
    report_no: number;
    incident_start_date: string;
    advisory: string;
    description: string;
}

@Component({
    selector: 'eaglei-urbannet-metadata-modal',
    templateUrl: './urbannet-metadata-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './urbannet-metadata-modal.component.scss'],
})
export class UrbannetMetadataModalComponent extends BaseModal implements OnInit {
    public report: UrbannetReport;
    public metadata: Metadata;

    public parseError: boolean = false;

    constructor(public dialogRef: MatDialogRef<UrbannetMetadataModalComponent>, @Inject(MAT_DIALOG_DATA) public data: UrbannetReport) {
        super();

        this.report = data;

        try {
            this.metadata = JSON.parse(this.report.metadata);
        } catch (e) {
            console.error(e);
            this.parseError = true;
        }
    }

    ngOnInit(): void {}

    afterInit() {}
}
