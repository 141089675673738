<div class="eaglei-report">
    <div class="filters filter-bar">
        <mat-form-field class="filter-select">
            <input matInput #filter placeholder="Search User" (keyup)="filterUsers($event.target.value)" />
            <fa-icon matSuffix icon="search"></fa-icon>
        </mat-form-field>

        <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">Export</button>
    </div>

    <mat-menu #menu="matMenu">
        <button (click)="exportTable()" mat-menu-item>Export Table as CSV</button>
    </mat-menu>

    <div class="table-container">
        <table #materialTable mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    User Name
                    <eaglei-table-sort-header [columnName]="'userName'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td
                    #nameCell
                    mat-cell
                    *matCellDef="let alert"
                    [matTooltip]="alert.userName"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matTooltipDisabled]="!hasEllipsis(nameCell)"
                    class="ellipsis">
                    {{ alert.userName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="userEmailAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    User Email Address
                    <eaglei-table-sort-header [columnName]="'userEmailAddress'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td
                    #emailCell
                    mat-cell
                    *matCellDef="let alert"
                    [matTooltip]="alert.userEmail"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [matTooltipDisabled]="!hasEllipsis(emailCell)"
                    class="ellipsis">
                    {{ alert.userEmail }}
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name
                    <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">{{ alert.name }}</td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Type
                    <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">{{ alert.type | titlecase }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell class="large-column" *matHeaderCellDef mat-sort-header>
                    Description <eaglei-table-sort-header [columnName]="'description'" [sort]="sort"></eaglei-table-sort-header>
                </th>

                <td mat-cell class="large-column" *matCellDef="let alert">{{ alert.description }}</td>
            </ng-container>

            <ng-container matColumnDef="threshold">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Threshold
                    <eaglei-table-sort-header [columnName]="'threshold'" [sort]="sort"></eaglei-table-sort-header>
                </th>
                <td mat-cell *matCellDef="let alert">{{ alert.threshold | number : '1.0-0' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns; let odd = odd"></tr>
        </table>
    </div>

    <div class="show-page" [class.hide-page]="loading">
        <div>
            <div *ngIf="dataSource && !(dataSource.filteredData.length > 0)" class="table-mask">
                <b>No Data Matching the Filters</b>
            </div>
            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div class="table-attribution">
        <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
    </div>
</div>
