import {AfterViewInit, Component, ElementRef, inject, ViewChildren} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import moment from 'moment';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {OngService} from '../../../../services/ong.service';
import {FileDownload} from '../../../../classes/file-download';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {FormControl, FormGroup} from '@angular/forms';
import {map, switchMap, tap} from 'rxjs/operators';
import {EagleINumberPipeArgs} from '../../../../../shared/pipes/eaglei-number.pipe';
import {DieselIntel, DieselIntelChild} from '../../classes/diesel-report';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject, Subject} from 'rxjs';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';

@Component({
    selector: 'eaglei-diesel-stock-report',
    templateUrl: './diesel-stock-report.component.html',
    styleUrls: ['../reports.scss', './diesel-stock-report.component.scss'],
})
export class DieselStockReportComponent extends Report<DieselIntel> implements AfterViewInit {
    @ViewChildren('chart') charts: ElementRef<HTMLDivElement>[];
    public chartColors = {
        Gasoline: 'green',
        Heating_Oil_Diesel: 'orange',
        Jet_Fuel: 'blue',
        Ethanol: 'red',
    };
    public baseCharts = {
        utilization: new EagleiBaseChart(),
        percent_change: new EagleiBaseChart(),
        change: new EagleiBaseChart(),
    };
    private loading$ = new BehaviorSubject<boolean>(false);
    private getReportData$ = this.reportService
        .getReportData()
        .pipe(takeUntilDestroyed())
        .subscribe((r) => {
            this.initializeReportInfo(r);
        });

    protected ongService = inject(OngService);
    private dieselStockDataSource$ = new Subject<{startDate: moment.Moment; endDate: moment.Moment}>();
    private dieselStock$ = this.dieselStockDataSource$
        .pipe(
            tap(() => {
                this.loading$.next(true);
            }),
            switchMap(({startDate, endDate}) => {
                return this.ongService.getDieselStockReport(startDate, endDate);
            }),
            tap(() => {
                this.loading$.next(false);
            }),
            takeUntilDestroyed()
        )
        .subscribe((res) => {
            this.initData(res);
        });

    public maskText$ = this.loading$.pipe(
        map((loading) => {
            return loading ? 'Loading report...' : 'No data matches your current filters.';
        })
    );

    public datePickerMinDate = moment().subtract(12, 'months');
    public startDate = moment().subtract(7, 'days');
    public endDate = moment();
    public percentPipeArgs: EagleINumberPipeArgs = {
        specifier: ',.2f',
        postfix: '%',
    };
    public momentPipeFormat = 'D MMM, YYYY';
    public readonly columnNames: string[] = ['product', 'previous_storage', 'current_storage', 'change', 'percent_change', 'utilization'];

    public filterForm = new FormGroup({
        startDate: new FormControl(this.startDate),
        endDate: new FormControl(this.endDate),
    });

    public productSelectedRow: DieselIntel;
    public expandedProductSelectedRow: MatTableDataSource<DieselIntelChild> = new MatTableDataSource<DieselIntelChild>();

    constructor(public reportService: ReportService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.getDieselStockReport();
        this.charts.forEach((chart) => {
            const id = chart.nativeElement.id;
            this.initializeChart(chart.nativeElement, id);
        });
    }

    public getDieselStockReport(): void {
        const {startDate, endDate} = this.filterForm.getRawValue();

        this.dieselStockDataSource$.next({startDate, endDate});
    }

    private initData(data: DieselIntel[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<DieselIntel>(data);
        }
        this.createCharts();
    }

    public updateDateRange(dates: CardFilters) {
        this.filterForm.patchValue({
            startDate: dates.startDate,
            endDate: dates.endDate,
        });
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getDieselStockReport();
    }

    public togglePrimaryExpandedRow(row: DieselIntel): void {
        const isRowSelected = this.productSelectedRow === row;
        this.productSelectedRow = isRowSelected ? undefined : row;
        this.expandedProductSelectedRow.data = isRowSelected ? [] : row.utilizations;
    }

    public exportTable(): void {
        let data = [
            'Product',
            'Report Date',
            'Storage Start',
            'Storage End',
            'Week to Week Change',
            'Percent Change',
            'Capacity Utilization',
            '\n',
        ].join();

        this.dataSource.data.forEach((val) => {
            let values = [
                FileDownload.formatCsvCell(val.product),
                'Average',
                FileDownload.formatCsvCell(val.previous_storage),
                FileDownload.formatCsvCell(val.current_storage),
                FileDownload.formatCsvCell(val.change),
                FileDownload.formatCsvCell(val.percent_change),
                FileDownload.formatCsvCell(val.utilization),
            ];

            data += values.join();
            data += '\n';

            val.utilizations.forEach((r) => {
                values = [
                    '',
                    FileDownload.formatCsvCell(Report.momentPipe.transform(r.report_date, this.momentPipeFormat)),
                    FileDownload.formatCsvCell(r.previous_storage),
                    FileDownload.formatCsvCell(r.current_storage),
                    FileDownload.formatCsvCell(r.change),
                    FileDownload.formatCsvCell(r.percent_change),
                    FileDownload.formatCsvCell(r.utilization),
                ];

                data += values.join();
                data += '\n';
            });

            data += '\n';
        });

        FileDownload.downloadCSV('diesel_stock_report', data, this.attributionUrl);
    }

    private initializeChart(chart: HTMLDivElement, id: string) {
        this.baseCharts[id].initializeEChart(chart, true, 'Date', '');
    }

    private createCharts() {
        this.charts.forEach((chart) => {
            const id = chart.nativeElement.id;
            this.createChart(chart, this.dataSource.data, id);
        });
    }

    private createChart(chart: ElementRef<HTMLDivElement>, data: DieselIntel[], id: string) {
        if (!data[0]) {
            return;
        }

        const xValues: string[] = data[0].utilizations.map((u) => moment(u.report_date).format('MM-DD-YYYY'));
        const yValues = [];
        const keys = {
            Gasoline: [],
            Heating_Oil_Diesel: [],
            Jet_Fuel: [],
            Ethanol: [],
        };

        data.forEach((intel) => {
            const product = intel.product.replace(/\/|\s/g, '_');
            let popoverlabel: string;
            switch (id) {
                case 'change': {
                    popoverlabel = 'Barrels';
                    break;
                }
                case 'percent_change': {
                    popoverlabel = 'Change';
                    break;
                }
                case 'utilization': {
                    popoverlabel = 'Capacity';
                    break;
                }
            }
            intel.utilizations.forEach((util) => {
                keys[product].push({
                    value: util[id],
                    popoverData: [
                        new PopoverElement().setTitle().setValue(`${intel.product}`),
                        new PopoverElement().setLabel(`${popoverlabel}:`).setValue(`${util[id]}${id === 'change' ? '' : '%'}`),
                    ],
                });
            });
        });

        Object.keys(keys).forEach((product) => {
            yValues.push({
                type: 'line',
                data: keys[product],
                lineStyle: {
                    color: this.chartColors[product],
                },
                itemStyle: {
                    color: this.chartColors[product],
                },
            });
        });

        this.baseCharts[id].eChartOptions.yAxis['scale'] = true;
        if (id === 'percent_change' || id === 'utilization') {
            this.baseCharts[id].eChartOptions.yAxis['axisLabel'].formatter = `{value}%`;
        }
        if (id === 'change') {
            this.baseCharts[id].eChartOptions.yAxis['name'] = 'Barrels';
        }
        this.baseCharts[id].eChartOptions.xAxis = {
            data: xValues,
        };
        this.baseCharts[id].eChartOptions.series = yValues;
        this.baseCharts[id].eChart.setOption(this.baseCharts[id].eChartOptions);
    }
}
