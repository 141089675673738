<div class="filters filter-bar">
    <mat-form-field class="filter-select" [class.active]="isSearchTextActive()">
        <mat-label *ngIf="isSearchTextActive()">Search API-Keys</mat-label>
        <!--suppress TypeScriptUnresolvedVariable -->
        <input matInput placeholder="Search API-Keys" (keyup)="filterKeys($event.target.value)" />
        <fa-icon matSuffix class="icon input-search-icon" icon="search"></fa-icon>
    </mat-form-field>

    <mat-button-toggle-group [value]="activeStatus" (change)="filterStatus($event)">
        <mat-button-toggle *ngFor="let status of keyStatuses" [value]="status">{{ status | titlecase }}</mat-button-toggle>
    </mat-button-toggle-group>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="exportMenu">
        <fa-icon icon="file-export" inline></fa-icon>
    </button>
</div>

<mat-card>
    <mat-card-content>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true" matSortActive="data" matSortDirection="desc">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="ei-sm-column">Status</th>
                    <td mat-cell *matCellDef="let apikey" class="ei-sm-column">
                        <span class="failure-text" *ngIf="apikey.expired">Expired</span>
                        <span class="success-text" *ngIf="!apikey.expired">Active</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef class="api-key-column">API-Key</th>
                    <td mat-cell *matCellDef="let apikey" class="api-key-column">
                        {{ apikey.key }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="user" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                        Username
                        <eaglei-table-sort-header [columnName]="'user'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let apikey"
                        class="ellipsis ei-md-column"
                        [matTooltip]="apikey.username"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ apikey.username }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                        Times Used For Data Access
                        <eaglei-table-sort-header [columnName]="'data'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let apikey">{{ apikey.useCount | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="ogc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                        Times Used For OGC Services
                        <eaglei-table-sort-header [columnName]="'ogc'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let apikey">{{ apikey.ogcCount | eagleiNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="expiration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Expiration Date
                        <eaglei-table-sort-header [columnName]="'expiration'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let apikey">
                        <p class="ellipsis">{{ apikey.expirationDate | momentDate }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="system">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-lg-column">
                        System Supported
                        <eaglei-table-sort-header [columnName]="'system'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let apikey"
                        class="ei-lg-column ellipsis"
                        [matTooltip]="apikey.system"
                        matTooltipPosition="after"
                        matTooltipShowDelay="250">
                        {{ apikey.system }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                        Name
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let apikey"
                        [matTooltip]="apikey.fullName"
                        class="ellipsis"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ apikey.fullName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Email
                        <eaglei-table-sort-header [columnName]="'email'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let apikey"
                        class="ellipsis ei-md-column"
                        [matTooltip]="apikey.email"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ apikey.email }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="table-attribution">
            <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
        </div>
    </mat-card-content>
</mat-card>

<mat-menu #exportMenu="matMenu" overlapTrigger>
    <button mat-menu-item (click)="exportTable()">Export Table as CSV</button>
</mat-menu>
