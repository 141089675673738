<div class="filter-bar">
    <section class="filters">
        <eaglei-location-filter
            (locations)="updateStateFilter($event)"
            [defaultSelection]="selectedStates"
            [selectAllOption]="true"></eaglei-location-filter>

        <eaglei-date-range [start]="startDate" [end]="endDate" [min]="minDate" (change)="updateDates($event)"></eaglei-date-range>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export" inline></fa-icon>
    </button>
</div>

<div class="filter-chips">
    <span class="filter-text">Filters: </span>
    <eaglei-filter-chip-list [states]="selectedStates" (change)="changeSelectedChips($event)"></eaglei-filter-chip-list>
</div>

<div class="report-data">
    <div class="chartCard">
        <ng-container [ngTemplateOutlet]="barChart"></ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<ng-template #barChart>
    <mat-card class="chart">
        <mat-card-content>
            <div class="chart-target" [class.invisible]="dataSource && dataSource.data.length === 0">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div class="auto-mask hide-mask" *ngIf="(dataSource && dataSource.data.length === 0) || !dataSource">
            <span class="mask-message">
                <span>{{ getMaskText() }}</span>
            </span>
        </div>
    </mat-card>
</ng-template>
<ng-template #table>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    #materialTable
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="totalCustomers"
                    matSortDirection="desc">
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.stateName }}</td>
                        <td mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>

                    <ng-container matColumnDef="totalCustomers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total Customers
                            <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.eiaTotalCustomers | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(info) }}</span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{ totalCustomers | eagleiNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="coveredCustomers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Covered Customers
                            <eaglei-table-sort-header [columnName]="'coveredCustomers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.coveredCustomersBestCase | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(info) }}</span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{ totalCovered | eagleiNumber }}</td>
                    </ng-container>

                    <ng-container matColumnDef="coveragePercentage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Coverage Percentage
                            <eaglei-table-sort-header [columnName]="'coveragePercentage'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.percentage | percent : '1.2-2' }}</td>

                        <td mat-footer-cell *matFooterCellDef>{{ totalCoverage | percent : '1.2-2' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="trend">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center">Trend</th>
                        <td mat-cell *matCellDef="let info" style="text-align: center">
                            <fa-icon *ngIf="info.trend === 1" icon="arrow-up"></fa-icon>
                            <fa-icon *ngIf="info.trend === -1" icon="arrow-down"></fa-icon>
                            <fa-icon *ngIf="info.trend === 0" icon="minus"></fa-icon>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        (click)="viewStateHistory(row)"
                        class="clickable"></tr>
                    <tr mat-footer-row *matFooterRowDef="columnNames"></tr>
                </table>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
