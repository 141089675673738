import {MaxUtility} from './max-utility';
import {UtilityOutage} from './utility-outage';
import moment from 'moment';
import {GenDataQuality} from 'frontend/generated/serverModels/GenDataQuality';

export class UtilityIntegrity extends GenDataQuality {
    public outageData: UtilityOutage[];
    public missingDataPoints: number;

    constructor(utility?: GenDataQuality, outageData?: UtilityOutage[]) {
        super(utility);
        this.setOutageData(outageData || []);

        // this.lastReportedOutageRunStartTime = moment(this.lastReportedOutageRunStartTime);
        const httpRegex = new RegExp(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/gm
        );
        if (!this.mapURL?.match(httpRegex)) {
            this.mapURL = undefined;
        }
        if (!this.twitterURL?.match(httpRegex)) {
            this.twitterURL = undefined;
        }
    }

    private setOutageData(data: UtilityOutage[]): void {
        this.outageData = data.map((d) => new UtilityOutage(d));
    }
}
