import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DataService} from '../../../../services/data.service';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import moment from 'moment';
import {OutageSnapshotDate} from '../../classes/outage-snapshot-date';
import {filter} from 'rxjs/operators';
import {LayerService} from '../../../layer/services/layer.service';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletNomSource} from '../../../layer/sources/leaflet-nom-source';
import {FileDownload} from '../../../../classes/file-download';
import {State} from '../../../outage/classes/state';
import {ApplicationConfig} from '../../../../classes/application-config';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {GenPopoverDataType} from '../../../../../../generated/serverModels/GenPopoverDataType';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'eaglei-outage-snapshot-report',
    templateUrl: './outage-snapshot-report.component.html',
    styleUrls: ['../reports.scss', './outage-snapshot-report.component.scss'],
})
export class OutageSnapshotReportComponent extends Report implements OnInit, AfterViewInit, OnDestroy {
    get overridesVisible(): boolean {
        return ApplicationConfig.showOverrideColor.getValue();
    }
    // HTML properties
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Filter properties
    public states = DataService.states.getValue();
    public increments = [4, 8, 12, 24];
    public readonly maxDate = moment();
    public readonly minDate = moment().subtract(7, 'days');
    private showOverrideState = ApplicationConfig.showOverrideColor.getValue();

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.utility, GenOutageAggregationLevel.fema, GenOutageAggregationLevel.zip].includes(l)
    );

    public showMask: boolean;

    private sources: LeafletNomSource[] = [];

    // Data Properties
    public snapshots: OutageSnapshotDate[] = [];
    public numberOfSnapshots: number[] = [0, 1, 2, 3, 4, 5];

    public filterGroup: UntypedFormGroup;
    public nomLayer: LeafletMapLayer;

    // Map Properties
    public mapOptions: MapOptions;
    public dataLoaded: boolean;
    public statePreferences: State[];

    constructor(private reportService: ReportService, private layerService: LayerService) {
        super();
        this.snapshots.length = 7;

        this.statePreferences = ApplicationConfig.currentUserPreferences.getValue().getStates();

        this.layerService.getLayerByHandle().subscribe((layer) => {
            this.nomLayer = layer;

            layer.popoverData = layer.popoverData.filter((pd) => pd.type !== GenPopoverDataType.DATE && !pd.display?.startsWith('Last'));
        });

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: false,
            zoomToHome: false,
        };
    }

    ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        const controls = {
            aggregationLevel: new UntypedFormControl(),
            states: new UntypedFormControl(),
            startDate: new UntypedFormControl(),
            endDate: new UntypedFormControl(),
            interval: new UntypedFormControl(),
        };

        this.filterGroup = new UntypedFormGroup(controls);

        this.changeSelectedLocations(this.statePreferences);

        const defaultAggregation = ApplicationConfig.currentUserPreferences.getValue().getOutageAggregationLevel();

        this.filterGroup.controls.aggregationLevel.setValue(defaultAggregation);
        this.filterGroup.controls.interval.setValue(12);
        this.filterGroup.controls.startDate.setValue(moment().subtract(1, 'day').startOf('day'));
        this.filterGroup.controls.endDate.setValue(moment());

        this.getOutageSnapshots();

        this.filterGroup.valueChanges.pipe(filter((res) => Object.keys(res).every((key) => res[key]))).subscribe(() => {
            this.getOutageSnapshots();
        });
    }

    ngAfterViewInit(): void {
        this.paginator.pageSize = 6;
    }

    ngOnDestroy() {
        // Resetting the show override state to what it was when the report was accessed.
        ApplicationConfig.showOverrideColor.next(this.showOverrideState);
    }

    private getOutageSnapshots(): void {
        this.showMask = true;
        this.dataLoaded = false;

        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.reportService
            .getOutageSnapshot(this.filterGroup.value)
            .subscribe((res) => {
                HttpInterceptorService.deleteFromInterceptor(this.uiHandle);
                this.sources = [];
                this.snapshots = res;
                this.dataLoaded = true;
                this.showMask = res.length === 0;

                this.paginator.length = this.snapshots.length;
                this.paginator.firstPage();
            });
    }

    public isFilterValid(): boolean {
        return Object.keys(this.filterGroup.value).every((key) => this.filterGroup.value[key]);
    }

    public setMapRef(snapshot: OutageSnapshotDate, map: any) {
        const nomSource = new LeafletNomSource(this.nomLayer);
        nomSource.mapRef = map;
        // nomSource.showAllPopoverData = false;

        nomSource.updateLocationFilter(this.filterGroup.controls.states.value);
        nomSource.processFeatures(snapshot?.historicalData);

        nomSource.addToMap();
        nomSource.changeOpacity(0.8);

        this.sources.forEach((source) => {
            // @ts-ignore
            source.mapRef.sync(nomSource.mapRef);
            // @ts-ignore
            nomSource.mapRef.sync(source.mapRef);
        });

        nomSource.fitToFeatures();
        this.sources.push(nomSource);
    }

    public exportData(): void {
        const columns = ['State', 'County', 'Outage', 'Date'];
        let data = '';

        if (this.filterGroup.controls.aggregationLevel.value === GenOutageAggregationLevel.state) {
            columns.splice(1, 1);
        }
        data = `${columns.join(',')}\n`;

        this.snapshots.forEach((snapshot) => {
            snapshot.historicalData.forEach((outage) => {
                const outageInfo = [
                    FileDownload.formatCsvCell(outage.stateName),
                    FileDownload.formatCsvCell(outage.currentOutage),
                    FileDownload.formatCsvCell(outage.currentOutageRunStartTime.format()),
                ];

                if (this.filterGroup.controls.aggregationLevel.value === GenOutageAggregationLevel.county) {
                    outageInfo.splice(1, 0, FileDownload.formatCsvCell(outage.countyName));
                }

                data += `${outageInfo.join(',')}\n`;
            });
        });

        FileDownload.downloadCSV('outageSnapshot', data, this.attributionUrl);
    }

    public changeSelectedLocations(event: State[]): void {
        this.filterGroup.controls.states.setValue(event);
    }

    public updateDates(event: CardFilters) {
        this.filterGroup.controls.startDate.setValue(event.startDate);
        this.filterGroup.controls.endDate.setValue(event.endDate);
    }

    public toggleOverrides() {
        ApplicationConfig.showOverrideColor.next(!ApplicationConfig.showOverrideColor.getValue());
        this.sources.forEach((source) => {
            source.redraw();
        });
    }

    public getMaskText(): string {
        return this.dataLoaded && this.snapshots.length === 0 ? 'No Snapshots Available' : 'Loading...';
    }

    public getPaginatorValues(): OutageSnapshotDate[] {
        if (!this.paginator) {
            console.warn('paginator not initialized');
        }

        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const endIndex = this.paginator.pageSize * (this.paginator.pageIndex + 1);
        return this.snapshots.slice(startIndex, endIndex);
    }

    public getSnapshot(mapId: number): OutageSnapshotDate {
        // Get the values for the paginator
        const values = this.getPaginatorValues();
        const snapshot = values[mapId];

        return snapshot;
    }

    public pageSnapshots(page: PageEvent): void {
        const values = this.getPaginatorValues();

        // Removes the current outage data on all maps
        this.sources.forEach((s) => {
            s.mapRef.removeLayer(s.source);
        });

        // Adds new outage data on all the maps
        this.sources.forEach((s, i) => {
            const snapshot = values[i];

            if (snapshot !== undefined) {
                s.processFeatures(snapshot?.historicalData);
                s.redraw();
                s.addToMap();
            }
        });
    }
}
