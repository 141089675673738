import {RecentEvent} from '../../recent-events/classes/recent-event';

export class OeData extends RecentEvent {
    public type = 'No Type Provided';
    public organization = 'No Organization Provided';

    constructor(data?: OeData | RecentEvent) {
        super(data);

        if (data?.additionalInfo) {
            try {
                const info = JSON.parse(data.additionalInfo.value);
                this.organization = info.OrgName || 'No Organization Provided';
                this.type = info.EmergencyTypes || info.IncidentTypes || 'No Type Provided';
            } catch (e) {
                console.warn('Unable to parse additional data for OE DATA' + data.id);
            }
        }
    }
}
