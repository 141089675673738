import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {AlertFeed} from '../../classes/alert-feed';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {FileDownload} from '../../../../classes/file-download';

@Component({
    selector: 'eaglei-alert-feed-report',
    templateUrl: './alert-feed-report.component.html',
    styleUrls: ['../reports.scss', './alert-feed-report.component.scss'],
})
export class AlertFeedReportComponent extends Report<AlertFeed> implements OnInit {
    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Filter Properties
    public startDate: moment.Moment = moment().subtract(1, 'days');
    public endDate: moment.Moment = moment();
    public activeAlerts: 'all' | 'active' | 'inactive' = 'all';

    // Table properties
    public readonly columns = ['status', 'alertName', 'hoursActive', 'customersOut', 'alertType', 'alertStart'];

    constructor(public reportService: ReportService) {
        super();
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        this.getAlerts();
    }

    // API Calls
    /**
     * Getting the Alerts for the table
     */
    private getAlerts(): void {
        this.reportService.getAlertFeed(this.startDate, this.endDate).subscribe((res) => {
            this.initializeData(res);
        });
    }

    // Table Methods
    /**
     * Creates the source for the table to use the data of the alerts
     * @param data Alerts used in table
     */
    private initializeData(data: AlertFeed[]): void {
        if (!this.dataSource) {
            this.dataSource = new MatTableDataSource<AlertFeed>(data.map((d) => new AlertFeed(d)));
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        } else {
            this.dataSource.data = data;
        }
    }

    /**
     * Logic of filtering the alerts in the table
     * @param data Alert to check
     */
    private filterPredicate(data: AlertFeed): boolean {
        if (this.activeAlerts === 'all') {
            return true;
        }

        return this.getActive(data).toLowerCase() === this.activeAlerts;
    }

    /**
     * Logic of sorting the alerts in the table
     * @param data Alert to sort
     * @param columnName Column being sorted
     */
    private dataAccessor(data: AlertFeed, columnName: string): string | number {
        switch (columnName) {
            case 'status':
                return this.getActive(data);
            case 'alertType':
                return data.alertType;
            case 'alertName':
                return data.alertName;
            case 'hoursActive':
                return data.hoursActive;
            case 'customersOut':
                return data.customersOut;
            case 'alertStart':
                return data.alertStart;
            default:
                return '';
        }
    }

    // Filter Methods
    /**
     * Activates the table filtering
     */
    public filterActive(): void {
        this.dataSource.filter = ' ';
    }

    /**
     * Updated the date range and calls the API for alerts in the range
     * @param dates The new start and end times for update
     */
    public changeDateRange(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getAlerts();
    }

    // Table Row Helper Methods
    /**
     * Returns proper string to show the status of the alert
     * @param alert Alert to check
     */
    public getActive(alert: AlertFeed): string {
        return alert.activeInd ? 'Active' : 'Inactive';
    }

    public getAlertType(alert: AlertFeed): string {
        if (alert.alertType === 'county') {
            return 'County';
        }
        if (alert.alertType === 'region') {
            if (alert.rollUp === true) {
                return 'Region';
            } else {
                return 'Region (Applied to states individually in region)';
            }
        }
        return 'State';
    }

    // Export Methods
    /**
     * Exports the table as a CSV
     */
    public exportTable(): void {
        let data = '';

        data += `Alerts from ${Report.momentPipe.transform(this.startDate)} to ${Report.momentPipe.transform(this.endDate)}`;
        data += '\n\n';

        data += this.columns
            .map((val) => {
                val = val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data += [
                FileDownload.formatCsvCell(this.getActive(val)),
                FileDownload.formatCsvCell(val.alertName.toString()),
                FileDownload.formatCsvCell(val.hoursActive.toString()),
                FileDownload.formatCsvCell(val.customersOut.toString()),
                FileDownload.formatCsvCell(this.getAlertType(val)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(val.alertStart)),
            ].join();
            data += '\n';
        });

        FileDownload.downloadCSV('alertsSent', data, this.attributionUrl);
    }
}
