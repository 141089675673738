import {Component, ElementRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {GenDataQuality} from 'frontend/generated/serverModels/GenDataQuality';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-utility-link-modal',
    templateUrl: './utility-link-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './utility-link-modal.component.scss'],
})
export class UtilityLinkModalComponent extends BaseModal {
    public utility: GenDataQuality;
    public ds: MatTableDataSource<any>;
    public columnNames = ['url', 'url_type'];

    constructor(
        public dialogRef: MatDialogRef<UtilityLinkModalComponent>,
        public element: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.utility = data;
    }

    private initializeTable(data: any[]) {
        this.ds = new MatTableDataSource<any>(data);
    }

    afterInit() {
        this.initializeTable(this.utility.outageURLs);
    }
}
