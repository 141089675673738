<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select active">
            <mat-label *ngIf="!!selectedPriority">Priority</mat-label>
            <mat-select placeholder="Priority" [value]="selectedPriority" (selectionChange)="updatePriority($event.value)">
                <mat-option [value]="'all'">All</mat-option>
                <mat-option [value]="'1'">1</mat-option>
                <mat-option [value]="'2'">2</mat-option>
                <mat-option [value]="'3'">3</mat-option>
                <mat-option [value]="'4'">4</mat-option>
                <mat-option [value]="'5'">5</mat-option>
            </mat-select>
        </mat-form-field>

        <eaglei-date-range
            [singlePicker]="true"
            [startPlaceholder]="'Active Date'"
            [start]="selectedActiveDate"
            [min]="ongService.minDate"
            (change)="updateDateRange($event)"></eaglei-date-range>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>

<ng-template #table>
    <mat-card>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Pipeline Name
                        <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Subject
                        <eaglei-table-sort-header [columnName]="'subject'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.subject }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Start Date
                        <eaglei-table-sort-header [columnName]="'start'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.start_date | momentDate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        End Date
                        <eaglei-table-sort-header [columnName]="'end'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.end_date | momentDate }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Priority
                        <eaglei-table-sort-header [columnName]="'priority'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.priority }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Quantity (mmbtu)
                        <eaglei-table-sort-header [columnName]="'quantity'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">
                        {{ info.quantity_estimate_mmbtu | eagleiNumber }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                <tr mat-row *matRowDef="let row; columns: columnNames" class="clickable hoverable" (click)="openMaintenanceModal(row)"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="table-attribution">
            <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
        </div>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
