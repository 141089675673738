<div class="filters filter-bar">
    <!-- Filters -->
    <mat-form-field class="filter-select" [class.active]="isSearchTextActive()">
        <mat-label *ngIf="isSearchTextActive()">Search Titles</mat-label>
        <!--suppress TypeScriptUnresolvedVariable -->
        <input matInput placeholder="Search Titles" (keyup)="filterTitles($event.target.value)" />
        <fa-icon matSuffix class="icon input-search-icon" icon="search"></fa-icon>
    </mat-form-field>

    <mat-form-field class="filter-select" [class.active]="selectedCompanies.length !== 0">
        <mat-label>Company</mat-label>
        <mat-select multiple placeholder="Company" [value]="selectedCompanies" (selectionChange)="updateCompany($event.value)">
            <mat-option *ngFor="let company of pipelineCompanies" [value]="company">
                {{ company }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-select" [class.active]="selectedPriorities.length !== 0">
        <mat-label>Priority</mat-label>
        <mat-select multiple placeholder="Priority" [value]="selectedPriorities" (selectionChange)="updatePriority($event.value)">
            <mat-option *ngFor="let priority of pipelineStatuses" [value]="priority">
                {{ priority }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <eaglei-location-filter [defaultSelection]="selectedStates" (locations)="updateLocations($event)"></eaglei-location-filter>

    <eaglei-date-range
        [start]="startDate"
        [end]="endDate"
        [min]="ongService.minDate"
        [max]="maxDate"
        [startPlaceholder]="'Effective Start Date'"
        [endPlaceholder]="'Effective End Date'"
        [newDesign]="true"
        (change)="updateDateRange($event)"></eaglei-date-range>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<!--State Chip List-->
<div class="filter-chips">
    <span class="title">Filters: </span>
    <mat-chip *ngFor="let chip of chipList | slice : 0 : maxChipLength" (removed)="removeFilter(chip)">
        <span class="chip-text"> {{ chip.display }} </span>
        <button matChipRemove>
            <fa-icon icon="times-circle"></fa-icon>
        </button>
    </mat-chip>

    <mat-chip *ngIf="chipList.length > maxChipLength && !showAll" (click)="showAll = true; maxChipLength = chipList.length">
        <span class="chip-text"> +{{ chipList.length - maxChipLength }}</span>
    </mat-chip>
    <mat-chip *ngIf="chipList.length > 0 && showAll" (click)="showAll = false; maxChipLength = 6">
        <span class="chip-text"> Show Less</span>
    </mat-chip>
    <mat-chip (click)="clearChipList()">
        <span class="chip-text"> Clear All</span>
    </mat-chip>
</div>

<div class="report-data">
    <mat-card [class.invisible]="!hasData()">
        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                multiTemplateDataRows
                matSort
                [matSortDisableClear]="true"
                matSortActive="date"
                matSortDirection="desc">
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-xl-column">
                        Title
                        <eaglei-table-sort-header [columnName]="'title'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="title ei-xl-column"
                        [matTooltip]="info.subject"
                        matTooltipPosition="right"
                        matTooltipShowDelay="250">
                        <p class="ellipsis">{{ info.subject }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Company
                        <eaglei-table-sort-header [columnName]="'company'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.name }}</td>
                </ng-container>

                <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Priority
                        <eaglei-table-sort-header [columnName]="'priority'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.priority }}</td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Effective Date
                        <eaglei-table-sort-header [columnName]="'startDate'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.effective_date | momentDate }}</td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        End Date
                        <eaglei-table-sort-header [columnName]="'endDate'" [sort]="sort"></eaglei-table-sort-header>
                    </th>
                    <td mat-cell *matCellDef="let info">{{ info.end_date | momentDate }}</td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let info" [attr.colspan]="columns.length">
                        <div class="hide-expanded-row" [class.show-expanded-row]="isActiveEvent(info)">
                            <div class="event-header">
                                From <b>Natural Gas Notices</b> at <b>{{ info.effective_date | momentDate }}</b>
                            </div>

                            <br />
                            <p class="event-content" [innerHTML]="info.subject | sanitize"></p>
                            <p *ngIf="info.type">({{ info.type }})</p>
                            <br />

                            <ng-container>
                                <div class="additional-info">Additional Information</div>
                                <p class="event-content" [innerHTML]="info.body | sanitize"></p>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: columns; let odd = odd"
                    (click)="toggleActiveReport(row)"
                    class="clickable hoverable"
                    [class.selected-row]="isActiveEvent(row)"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="expandable-row"
                    [class.expanded]="isActiveEvent(row)"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="table-attribution">
            <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
        </div>
    </mat-card>

    <div class="auto-mask hide-mask" *ngIf="pipelines.length === 0">
        <span class="mask-message" [class.no-data]="pipelines">{{ getMaskText() }}</span>
    </div>
</div>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
