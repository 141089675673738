<!--This is used for $event.target.value since target is a generic type but we know it is an HTMLElement-->
<!--suppress TypeScriptUnresolvedVariable -->

<mat-drawer-container>
    <mat-drawer #edit mode="over" class="drawer edit-report new-design" position="end">
        <form class="drawer-form">
            <div class="drawer-inputs">
                <mat-form-field class="character-limit-hint" subscriptSizing="fixed">
                    <mat-label class="title">Report Name</mat-label>
                    <input
                        matInput
                        name="displayName"
                        placeholder="Report Name"
                        [(ngModel)]="tmpReport.displayName"
                        [formControl]="reportNameControl" />
                    <mat-hint *ngIf="tmpReport.displayName" align="end">{{ tmpReport.displayName.length }} / 120</mat-hint>
                    <mat-error *ngIf="reportNameControl.invalid">
                        <span *ngIf="reportNameControl.hasError('required')">A report name is required</span>
                        <span *ngIf="reportNameControl.hasError('onlySpace')">Report name can not only be a space </span>
                        <span *ngIf="reportNameControl.hasError('nameExists')">Report name already exists</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="character-limit-hint">
                    <mat-label class="title">Report Description</mat-label>
                    <textarea
                        matInput
                        name="description"
                        placeholder="Report Description"
                        [(ngModel)]="tmpReport.description"
                        maxlength="500"></textarea>
                    <mat-hint *ngIf="tmpReport.description" align="end">{{ tmpReport.description.length }} / 500 </mat-hint>
                </mat-form-field>

                <div class="roles">
                    <mat-label class="title">Report Roles</mat-label>
                    <eaglei-autocomplete #autocomplete [values]="roles" [placeholder]="'Select Role'"></eaglei-autocomplete>
                </div>

                <mat-form-field class="filter-select">
                    <mat-label class="title">Report Group</mat-label>
                    <mat-label>Select Group</mat-label>
                    <mat-select name="group" placeholder="Select Group" [(ngModel)]="selectedGroup">
                        <mat-option *ngFor="let group of reportGroups" [value]="group"> {{ group.name }}</mat-option>
                    </mat-select>

                    <fa-icon class="suffix" icon="chevron-down" matSuffix></fa-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label class="title">Attribution Title</mat-label>
                    <input matInput name="attributionTitle" placeholder="Report Name" [(ngModel)]="tmpReport.attributionTitle" />
                    <mat-hint align="start">
                        to place a &trade; symbol, input <b>{{ '{TRADEMARK}' }}</b>
                    </mat-hint>
                </mat-form-field>

                <ng-container *ngIf="!tmpReport.externalAttribution; else externalAttribution">
                    <div class="add-attribution-url">
                        <span class="title">Attribution URL</span>
                        <fa-icon icon="fa-plus" matTooltip="Add Attribution URL" (click)="addAttribution()"></fa-icon>
                    </div>

                    <ng-container *ngIf="edit.opened">
                        <ng-container *ngFor="let url of attributionURLs; index as i; trackBy: trackAttribution">
                            <mat-form-field subscriptSizing="fixed">
                                <input
                                    matInput
                                    [(ngModel)]="attributionURLs[i]"
                                    (ngModelChange)="updateAttr($event, i)"
                                    [formControl]="getFormControl(i)" />
                                <mat-error>
                                    <span *ngIf="getFormControl(i).hasError('required')"> An attribution url is required </span>
                                    <span *ngIf="getFormControl(i).hasError('pattern')">
                                        {{ getFormControl(i).errors['pattern'] }}
                                    </span>
                                </mat-error>
                                <button
                                    *ngIf="attributionURLs.length > 1"
                                    mat-icon-button
                                    matSuffix
                                    (click)="removeAttribution(i)"
                                    matTooltip="Remove Attribution URL">
                                    <fa-icon icon="times"></fa-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #externalAttribution>
                    <span>Unable to modify attribution URL's for report with external attributions</span>
                </ng-template>

                <mat-slide-toggle labelPosition="before" [checked]="tmpReport.active" (change)="tmpReport.active = $event.checked"
                    >Enabled
                </mat-slide-toggle>
            </div>

            <div class="drawer-actions">
                <button mat-stroked-button color="warn" (click)="deleteReport()">Delete Report</button>
                <button
                    mat-flat-button
                    color="primary"
                    (click)="updateReport()"
                    [disabled]="reportNameControl.invalid || attrGroup.invalid">
                    Update Report
                </button>
            </div>
        </form>
    </mat-drawer>

    <mat-drawer-content>
        <div class="new-design widthLimiter">
            <h1 class="title">Report Management</h1>

            <div class="filter-bar ignore-last">
                <section class="filters">
                    <mat-form-field class="filter-select" [class.active]="hasSearchText">
                        <mat-label *ngIf="hasSearchText">Search Reports</mat-label>

                        <input matInput placeholder="Search Reports" (keyup)="searchReports($event.target.value)" />

                        <fa-icon matSuffix class="icon input-search-icon" icon="search"></fa-icon>
                    </mat-form-field>
                </section>

                <div class="icon-list">
                    <fa-icon icon="sort-alpha-down" class="clickable" (click)="sortReports()" [class.sorted]="sorted"></fa-icon>

                    <mat-button-toggle-group class="group-container" [value]="filteredGroupId">
                        <mat-button-toggle
                            *ngFor="let group of reportGroups"
                            (change)="filterToGroup($event.value)"
                            [value]="group.id"
                            [matTooltip]="group.name"
                            matTooltipPosition="above"
                            matTooltipShowDelay="250">
                            <fa-icon [icon]="group.iconUrl"></fa-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>

            <div class="report-container">
                <div *ngFor="let group of filteredGroups" class="report-group">
                    <span class="title">{{ group.name }}</span>
                    <mat-card class="clickable" *ngFor="let report of group.dataProviders" (click)="editReport(report, group)">
                        <mat-card-content class="report-card">
                            <fa-icon [icon]="report.iconUrl" class="group-icon" inline></fa-icon>
                            <div class="report-info">
                                <p class="report-name ellipsis">{{ report.displayName }}</p>
                                <p class="sub-title ellipsis">{{ report.description }}</p>
                            </div>

                            <ng-container *ngIf="isEnabled(report)">
                                <fa-icon
                                    class="pin-icon pinned"
                                    [icon]="['far', 'eye']"
                                    matTooltip="Enabled"
                                    matTooltipPosition="above"
                                    matTooltipShowDelay="250"></fa-icon>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
