import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AlertSubscription} from '../../../alert/classes/alert-subscription';
import {ReportService} from '../../services/report.service';
import {AlertService} from '../../../alert/services/alert.service';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FileDownload} from 'frontend/src/app/classes/file-download';

@Component({
    selector: 'eaglei-alert-subscription-report',
    templateUrl: './alert-subscription-report.component.html',
    styleUrls: ['../reports.scss', './alert-subscription-report.component.scss'],
})
export class AlertSubscriptionReportComponent extends Report<AlertSubscription> implements OnInit {
    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // Data Properties
    public subscribers: AlertSubscription[];
    public loading = true;

    // Table properties
    public readonly columns = ['userName', 'userEmailAddress', 'name', 'type', 'description', 'threshold'];

    constructor(private alertService: AlertService, public reportService: ReportService) {
        super();
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        this.alertService.getCurrentSubscriptions(true).subscribe((res) => {
            this.subscribers = res;
            this.loading = false;
            this.initializeData(this.subscribers);
        });
    }

    private initializeData(data: AlertSubscription[]) {
        this.dataSource = new MatTableDataSource<AlertSubscription>(data);
        this.dataSource.sortingDataAccessor = this.dataAccessor;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
    }

    // noinspection JSMethodCanBeStatic
    private filterPredicate(data: AlertSubscription, filter: string): boolean {
        return [data.userEmail.toLowerCase(), data.userName.toLowerCase()].join().includes(filter.toLowerCase().trim());
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: AlertSubscription, columnName: string): string | number {
        switch (columnName) {
            case 'userName':
                return data.userName.toLowerCase();
            case 'userEmailAddress':
                return data.userEmail.toLowerCase();
            case 'name':
                return data.name;
            case 'type':
                return data.type;
            case 'description':
                return data.description;
            case 'threshold':
                return data.threshold;
            default:
                return '';
        }
    }

    exportTable() {
        let data = '';
        data += this.columns
            .map((val) => {
                val = val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((alert) => {
            data += [
                FileDownload.formatCsvCell(alert.userName),
                FileDownload.formatCsvCell(alert.userEmail),
                FileDownload.formatCsvCell(alert.name),
                FileDownload.formatCsvCell(alert.type),
                FileDownload.formatCsvCell(alert.description),
                FileDownload.formatCsvCell(alert.threshold),
            ].join();
            data += '\n';
        });
        FileDownload.downloadCSV('alertSubscriptions', data, this.attributionUrl);
    }

    public filterUsers(filter: string): void {
        this.dataSource.filter = filter;
    }

    hasEllipsis(element: HTMLElement) {
        return ApplicationConfig.hasEllipsis(element);
    }
}
