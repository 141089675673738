import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import {Report} from '../../classes/report';
import {LayerService} from '../../../layer/services/layer.service';
import {DataService} from '../../../../services/data.service';
import {ReportService} from '../../services/report.service';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import moment from 'moment';
import {FileDownload} from '../../../../classes/file-download';
import {ApplicationConfig} from '../../../../classes/application-config';
import {GenOutageAggregationLevel} from '../../../../../../generated/serverModels/GenOutageAggregationLevel';
import {AutoUpdate} from 'frontend/src/shared/classes/auto-update';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {State} from '../../../outage/classes/state';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTooltip} from '@angular/material/tooltip';
import {MapOptions} from '../../../map/classes/map-options';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LeafletNomSource} from '../../../layer/sources/leaflet-nom-source';
import {LeafletNomFilter} from '../../../layer/filters/leaflet-nom-filter';
import {HistoricalOutageData} from '../../../layer/classes/historical-outage-data';
import {ModalConfig} from '../../../../classes/modal-config';
import {EmailReportDataModalComponent} from '../../../../../shared/modals/email-report-data-modal/email-report-data-modal.component';
import {ReportParameter} from '../../../../../shared/enums/report-parmeter.enum';
import {ReportRequest} from '../../../../../shared/classes/report-request';
import {ReportRequestParameter} from '../../../../../shared/classes/report-request-parameter';
import {take, takeUntil} from 'rxjs/operators';
import {NomChartComponent} from '../../../../../shared/components/nom-chart/nom-chart.component';
import {OutageService} from '../../../outage/services/outage.service';
import {ResponseWrapper} from 'frontend/src/app/classes/response-wrapper';
import {OutageChartDataModalComponent} from '../../../../../shared/modals/outage-chart-data-modal/outage-chart-data-modal.component';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {GenCountyCoverageData} from '../../../../../../generated/serverModels/GenCountyCoverageData';
import {CountyCoverageData} from '../../../../classes/county-coverage-data';

@Component({
    selector: 'eaglei-outage-report',
    templateUrl: './outage-report.component.html',
    styleUrls: ['../reports.scss', './outage-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutageReportComponent extends Report<HistoricalOutageData> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(NomChartComponent, {static: false}) nomChartComponent: NomChartComponent;

    @ViewChild('hourTooltip') hourTooltip: MatTooltip;
    @ViewChild('dayTooltip') dayTooltip: MatTooltip;

    private layerService = inject(LayerService);
    private outageService = inject(OutageService);
    public reportService = inject(ReportService);
    private dialog = inject(MatDialog);
    private ele = inject(ElementRef);

    // Modal Columns
    private readonly UTILITY_MODAL_COLUMNS: ColumnDef<GenCountyCoverageData>[] = [
        new ColumnDef<CountyCoverageData>('Utility Name', (val) => val.utilityName)
            .sort((val) => val.utilityName.toLowerCase())
            .setDefaultSort(true)
            .setHasTooltip(),
        new ColumnDef<CountyCoverageData>('Utility ID', (val) => val.utilityId).sort((val) => val.utilityId),
        new ColumnDef<CountyCoverageData>('SubUtility ID', (val) => val.subUtilityId || 'N/A').sort((val) => val.subUtilityId),
        new ColumnDef<CountyCoverageData>('Customers Out', (val) => Report.numberPipe.transform(val.outages)).sort((val) => val.outages),
        new ColumnDef<CountyCoverageData>('Total Customers', (val) => Report.numberPipe.transform(val.coveredCustomers))
            .sort((val) => val.coveredCustomers)
            .setSubscript((val) => ` (${val.coveredCustomersType})`, 'county-customer'),
    ];

    // Table Properties
    private readonly initialColumns = [
        'name',
        'customersOut',
        'totalCustomers',
        'percentOut',
        'lastHourMaxOutage',
        'lastDayMaxOutage',
        'fips',
    ];
    private readonly countyColumns = this.initialColumns.slice();
    private readonly zipColumns = this.initialColumns.slice();

    public displayedColumns: string[] = this.initialColumns.slice();

    // Chart Properties
    public chartStartDate: moment.Moment = ApplicationConfig.roundMinute().subtract(1, 'day');

    // Filter Properties
    public update = new AutoUpdate(this.updateCallback.bind(this)).setAutoUpdate(false);
    private refreshTimeout: any;
    private refreshInterval: any;

    public overrideColor: string;

    public minDate: moment.Moment = this.chartStartDate.clone().subtract(7, 'days');

    public readonly aggregationLevels = GenOutageAggregationLevel.values().filter(
        (l) => ![GenOutageAggregationLevel.utility, GenOutageAggregationLevel.fema].includes(l)
    );

    // Loading Mask Properties
    public chartVisible: boolean;
    public isLoading = true;
    public showMask = true;
    public showOverrideColor: boolean;

    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: LeafletNomSource;
    public filters = new LeafletNomFilter();

    /** ResponseWrapper for the table data */
    private apiData$: WritableSignal<ResponseWrapper<HistoricalOutageData> | undefined> = signal(undefined);
    public $pageIndex = signal(0);
    public $pageSize = signal(25);
    public $totalRows = computed(() => this.apiData$()?.totalNumRows ?? 0);
    /** The active sorted table column & direction */
    private sort$ = signal({active: 'customersout', direction: 'desc'});
    /** The table data */
    public $data = computed(() => this.apiData$()?.data ?? []);

    /** Retrieved for the map */
    private countyMapData?: HistoricalOutageData[];
    /** Retrieved for the map */
    private stateMapData?: HistoricalOutageData[];

    constructor() {
        super(undefined, true);

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    /** Converts the table column ID to the relevant data property for sorting. */
    private getSortField(columnName: string) {
        switch (columnName) {
            case 'fips':
                return 'fipscode';
            case 'name':
                return this.filters.aggregationLevel.toString();
            case 'lastHourMaxOutage':
                return 'max1';
            case 'lastDayMaxOutage':
                return 'max24';
            case 'customersOut':
            case 'totalCustomers':
            case 'percentOut':
                return columnName.toLowerCase();
            default:
                return columnName;
        }
    }

    handlePaginate(page: PageEvent) {
        this.$pageIndex.set(page.pageIndex);
        this.$pageSize.set(page.pageSize);
        this.getOutageData();
    }

    handleSort(sort: Sort) {
        this.sort$.set({...sort, active: this.getSortField(sort.active)});
        this.getOutageData(false, true);
    }

    // Lifecycle Hooks
    ngOnInit() {
        // This has to be done here, if done in the initialization it is an empty array
        this.countyColumns.splice(1, 0, 'state');
        this.zipColumns.splice(1, 0, 'state');
        this.zipColumns.pop();

        this.reportService
            .getReportData()
            .pipe(takeUntil(this.destroy$))
            .subscribe((r) => this.initializeReportInfo(r));

        this.getInitialPreferences();

        this.showOverrideColor = ApplicationConfig.showOverrideColor.getValue();

        this.layerService
            .getLayerByHandle()
            .pipe(takeUntil(this.destroy$))
            .subscribe((layer) => {
                this.mapLayer = layer;
                this.layerSource = new LeafletNomSource(layer);
                this.layerSource.updateLocationFilter(this.filters.states);

                this.getOutageData(true);
                this.getMapData();
            });
    }

    ngAfterViewInit() {
        ['scroll', 'touchmove', 'wheel'].forEach((type) => {
            this.ele.nativeElement.addEventListener(type, () => {
                this.dayTooltip?.hide();
                this.hourTooltip?.hide();
            });
        });
    }

    ngOnDestroy() {
        clearTimeout(this.refreshTimeout);
        clearInterval(this.refreshInterval);
        this.update.clear();
    }

    onApplicationTimeChange() {
        this.minDate = ApplicationConfig.roundMinute().subtract(7, 'day');
        this.chartStartDate = ApplicationConfig.roundMinute().subtract(1, 'day');

        this.updateCallback();
    }

    // Export Methods
    /**
     * Exports the data table as a CSV file
     */
    public exportTable() {
        let url = `/api/outagesummary/paginated-data-csv?allCounties=true&`;
        url += `start=${this.filters.runStartTime.format()}&`;
        url += `aggregation=${this.filters.aggregationLevel.toString()}&`;
        url += `sort=${this.sort$().active.concat(this.sort$().direction)}&`;
        url += `${this.filters.states.map((state) => `state=${state.abbreviation}`).join('&')}`;

        FileDownload.downloadFile(`outages_${moment().format('YYYYMMDDhhmmss')}.csv`, url);
    }

    /**
     * Exports the chart as CSV file
     */
    public exportChartAsCsv() {
        this.nomChartComponent.exportChartAsCsv('outageTrend', this.attributionUrl);
    }

    /**
     * Exports the chart as a PNG
     */
    public exportChart() {
        this.nomChartComponent.exportChartAsPng(undefined, 'outageChart', this.attributionUrl);
    }

    // Filter Methods
    /**
     * View listener for when the aggregation level is changed
     * @param event The event that is fired when aggregation level is changed
     */
    public changeAggregation(event: MatButtonToggleChange) {
        this.filters.aggregationLevel = event.value;
        this.applyFilters(false, false);
    }

    /**
     * Method fired when the state filter is updated by select or chip list
     * @param event the new list of filtered states
     */
    public changeSelectedLocations(event: State[]) {
        const filterIds = this.filters.states.map((state) => state.id).sort();
        const eventIds = event.map((state) => state.id).sort();
        const zoom = filterIds !== eventIds;

        this.filters.states = event.slice();
        this.applyFilters(zoom, true);
        this.layerSource.fitToFeatures();
    }

    /**
     * Method fired when the datepicker for the chart data is changed.
     * @param event the start date for the chart.
     */
    public changeChartDate(event: moment.Moment) {
        this.chartStartDate = event.clone();
    }

    /**
     * Applies all filters and makes API requests
     */
    private applyFilters(zoom = true, fetchData: boolean = true): void {
        this.chartVisible = false;

        if (this.layerSource) {
            this.layerSource.updateLocationFilter(this.filters.states);
        }

        this.getMapData(fetchData);

        this.getOutageData(zoom, true);
    }

    /**
     * Determines if overrides should be shown
     * @param active Show overrides if true
     */
    public toggleOverrideColor(active: boolean): void {
        this.showOverrideColor = active;

        ApplicationConfig.showOverrideColor.next(active);
        this.layerSource.redraw();
    }

    // Auto Update Methods
    /**
     * Toggles the auto update method for the report
     */
    public toggleAutoUpdate(): void {
        this.update.autoUpdate = !this.update.autoUpdate;
    }

    /**
     * The callback that is used for the auto update
     */
    private updateCallback(): void {
        this.filters.runStartTime = ApplicationConfig.roundMinute();
        // Added this to manually trigger the chart re-render
        this.chartStartDate = this.chartStartDate.clone();
        this.getOutageData();
        this.getMapData(true);
    }

    // Preference Methods
    /**
     * Gets the user preferences for initial page load.
     */
    private getInitialPreferences(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        if (preferences) {
            this.filters.aggregationLevel = preferences.getOutageAggregationLevel() || GenOutageAggregationLevel.state;
            this.filters.states = preferences.getStates();

            this.overrideColor = preferences.getOverrideColor();
        } else {
            this.filters.aggregationLevel = GenOutageAggregationLevel.state;
            this.overrideColor = ApplicationConfig.overrideColor;

            this.filters.states = DataService.states.getValue();
        }
    }

    // API Calls

    /**
     * Retrieves the data for updating the map
     * @param resetCache Whether to reset the cached data; useful for filter changes
     */
    private getMapData(resetCache = false) {
        // If we are on the zip level, we do not want to redraw the map
        if (this.filters.aggregationLevel === GenOutageAggregationLevel.zip) {
            this.layerSource.fetchNomTiles();
            this.layerSource.changeOpacity(this.layerSource.layerInfo.opacity);
            return;
        }
        if (resetCache) {
            this.countyMapData = undefined;
            this.stateMapData = undefined;
        }

        this.mapLayer?.startLoading();
        const mapKey = 'outage_report_map';
        HttpInterceptorService.clearInterceptor(mapKey);
        const data = this.filters.aggregationLevel === GenOutageAggregationLevel.county ? this.countyMapData : this.stateMapData;
        if (data?.length) {
            this.updateMap(data, true);
            return;
        }

        HttpInterceptorService.pendingRequests[mapKey] = this.layerService
            .getHistoricalOutageData(this.filters)
            .pipe(take(1))
            .subscribe({
                next: (res) => {
                    HttpInterceptorService.deleteFromInterceptor(mapKey);
                    if (this.filters.aggregationLevel === GenOutageAggregationLevel.county) {
                        this.countyMapData = res;
                    } else {
                        this.stateMapData = res;
                    }
                    this.updateMap(res, true);
                },
            });
    }

    /**
     * Makes API calls bases on the user filters for states and aggregation level, then creates a map layer and populates the table
     * @param zoomToFeatures When true, will zoom the map to fit the returned states
     * @param resetPageIndex Whether to reset the data to the first page
     */
    private getOutageData(zoomToFeatures: boolean = false, resetPageIndex = false): void {
        this.isLoading = true;
        this.showMask = !this.$data().length;
        // this.mapLayer?.startLoading();

        const {aggregationLevel, runStartTime, states} = this.filters;
        if (states.length) {
            HttpInterceptorService.clearInterceptor(this.interceptorKey);
            HttpInterceptorService.pendingRequests[this.interceptorKey] = this.outageService
                .getPaginatedOutageData<HistoricalOutageData>(
                    aggregationLevel,
                    runStartTime,
                    states,
                    resetPageIndex ? 1 : this.$pageIndex() + 1, // page query cannot be 0
                    this.$pageSize(),
                    this.sort$().active.concat(this.sort$().direction)
                )
                .pipe(take(1))
                .subscribe({
                    next: (response) => {
                        HttpInterceptorService.deleteFromInterceptor(this.interceptorKey);

                        this.updateTable(response, aggregationLevel, resetPageIndex);
                    },
                });
        } else {
            this.apiData$.set(undefined);
        }
    }

    /** Handles updating the map with new data */
    private updateMap(data: HistoricalOutageData[], zoomToFeatures: boolean) {
        this.layerSource.processFeatures(data);
        this.layerSource.changeOpacity(0.8);

        this.layerSource.addToMap(true);

        if (zoomToFeatures) {
            this.layerSource.fitToFeatures();
        }

        this.mapLayer.endLoading();
    }

    /** Handles updating the table data after an api response */
    private updateTable(response: ResponseWrapper, aggregationLevel: GenOutageAggregationLevel, resetPageIndex: boolean) {
        switch (aggregationLevel) {
            case GenOutageAggregationLevel.zip:
                this.displayedColumns = this.zipColumns;
                break;
            case GenOutageAggregationLevel.county:
                this.displayedColumns = this.countyColumns;
                break;
            default:
                this.displayedColumns = this.initialColumns;
        }

        if (resetPageIndex) {
            this.$pageIndex.set(0);
        }

        this.apiData$.set(response);
        this.isLoading = false;
        this.showMask = !response.data.length;
    }

    public openEmailModal(): void {
        const request = new ReportRequest();
        request.reportName = 'COBSR Report';
        request.userDefinedReportId = 1;
        request.parameters = [
            new ReportRequestParameter().setName(ReportParameter.AGGREGATION_LEVEL).setValue(this.filters.aggregationLevel),
            new ReportRequestParameter().setName(ReportParameter.STATE_IDS).setValue(this.filters.states.map((s) => s.id).join(',')),
        ];

        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            data: {
                request,
            },
            disableClose: true,
        };

        this.dialog.open(EmailReportDataModalComponent, config);
    }

    public openUtilityModal(row: HistoricalOutageData): void {
        // We only want to open this modal if for counties
        if (this.filters.aggregationLevel !== GenOutageAggregationLevel.county) {
            return;
        }

        this.outageService
            .getUtilitiesByCounty(row.countyFIPSCode, this.filters.runStartTime)
            .pipe(take(1))
            .subscribe((coverage: CountyCoverageData[]) => {
                const opts: MatDialogConfig = {
                    data: {
                        tableData: coverage,
                        columnDefs: this.UTILITY_MODAL_COLUMNS,
                        title: `Utility Coverage for ${row.countyName} (${row.stateName})`,
                        noDataMask: 'No Covered Utilities.',
                    },
                    autoFocus: false,
                    disableClose: true,
                    width: ModalConfig.getModalWidth(),
                };

                this.dialog.open(OutageChartDataModalComponent, opts);
            });
    }
}
