<div mat-dialog-title class="header">
    <span class="title">Comparison Data For {{ utilityName }} - {{ countyName }}</span>

    <button mat-icon-button matDialogClose>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<mat-dialog-content #content>
    <table
        #materialTable
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortDisableClear]="true"
        matSortActive="overrideCount"
        matSortDirection="desc">
        <ng-container matColumnDef="outageCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Outage Count
                <eaglei-table-sort-header [columnName]="'outageCount'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">{{ info.outageCount | number }}</td>
        </ng-container>

        <ng-container matColumnDef="overrideCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Override Count
                <eaglei-table-sort-header [columnName]="'overrideCount'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">{{ info.overrideCount | number }}</td>
        </ng-container>

        <ng-container matColumnDef="outageDifference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Override Difference
                <eaglei-table-sort-header [columnName]="'outageDifference'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">{{ info.outageDifference | number }}</td>
        </ng-container>

        <ng-container matColumnDef="runStartTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Run Start Time
                <eaglei-table-sort-header [columnName]="'runStartTime'" [sort]="sort"></eaglei-table-sort-header>
            </th>
            <td mat-cell *matCellDef="let info">{{ info.runStartTime | momentDate }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd" class="clickable"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
