<div class="dialog-header">
    <span>{{ utility.utilityName }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<mat-dialog-content #content>
    <span>
        <b>Additional URLs</b>
    </span>
    <table #materialTable mat-table class="test-design" [dataSource]="ds">
        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef class="url-column">URL</th>
            <td mat-cell *matCellDef="let source" class="url-column">
                <p class="ellipsis">
                    <a [href]="source.url" target="_blank">{{ source.url }}</a>
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="url_type">
            <th mat-header-cell *matHeaderCellDef>URL Type</th>
            <td mat-cell *matCellDef="let source">{{ source.urlType }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames; let odd = odd"></tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
