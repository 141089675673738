import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import {ApplicationConfig} from 'frontend/src/app/classes/application-config';
import {ChartBounds} from 'frontend/src/app/classes/chart-bounds';
import {PopoverElement} from 'frontend/src/app/classes/popover-element';
import {DataService} from 'frontend/src/app/services/data.service';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {AggregationLevelPipe} from 'frontend/src/shared/pipes/aggregation-level.pipe';
import {ChartDataService} from 'frontend/src/shared/services/chart-data.service';
import moment from 'moment';
import {filter, throttleTime} from 'rxjs/operators';
import {ModalConfig} from '../../../../classes/modal-config';
import {State} from '../../../outage/classes/state';
import {OutageRestoration} from '../../classes/outage-restoration';
import {Report} from '../../classes/report';
import {RestorationInfoModalComponent} from '../../modals/restoration-info-modal/restoration-info-modal.component';
import {ReportService} from '../../services/report.service';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {DataPoint} from '../../../../classes/data-point';
import {EagleiBaseChart} from 'frontend/src/app/classes/charts/base-chart';
import {IReportNavigationConfig} from '../../../../interfaces/report-navigation-config.interface';
import {Router} from '@angular/router';
import {ReportFilter} from '../../enums/report-filter.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-restoration-report',
    templateUrl: './restoration-report.component.html',
    styleUrls: ['../reports.scss', './restoration-report.component.scss'],
})
export class RestorationReportComponent extends Report<OutageRestoration> implements OnInit, AfterViewInit {
    // HTML Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    // Table properties
    public columnNames: string[] = [
        'state',
        'county',
        'outageCount',
        'restorationCount',
        'percentRestored',
        'trend',
        'maxOutage',
        'maxRestoration',
        'lastTimestamp',
    ];

    // Filter Properties
    public states: string[] = [];
    private _selectedStates: State[] = [];
    public level: 'state' | 'county' = 'county';
    public interval: 6 | 12 | 24 = 6;
    private endDate: moment.Moment = moment();
    public eDate: Date = this.endDate.clone().toDate();

    // Chart Properties
    public baseChart = new EagleiBaseChart<any, any, OutageRestoration>();
    private chartData: OutageRestoration[] = [];

    public isLoading = true;
    public minDate = moment().subtract(7, 'days').toDate();
    public maxDate = moment().toDate();

    get selectedStates(): State[] {
        return this._selectedStates;
    }

    set selectedStates(value: State[]) {
        this._selectedStates = value;
    }

    get showMask(): boolean {
        return this.isLoading || (this.dataSource !== undefined ? this.dataSource.filteredData.length <= 0 : true);
    }

    private router = inject(Router);

    constructor(
        public reportService: ReportService,
        private chartService: ChartDataService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar
    ) {
        super();
        const routeFilters: IReportNavigationConfig[] = this.router?.getCurrentNavigation()?.extras?.state?.filters;

        this.states = DataService.states.getValue().map((s) => s.abbreviation);

        ApplicationConfig.resizeEvent
            .pipe(
                filter(() => !!this.baseChart.eChart),
                throttleTime(500)
            )
            .subscribe(() => this.baseChart.eChart.resize());

        if (routeFilters) {
            routeFilters.forEach((routerFilter) => {
                switch (routerFilter.type) {
                    case ReportFilter.AGGREGATION:
                        this.level = routerFilter.value as 'state' | 'county';
                        this.updateLevel(false);
                        break;
                    case ReportFilter.END_DATE:
                        this.endDate = moment(routerFilter.value as string);
                        this.eDate = this.endDate.clone().toDate();
                        this.minDate = this.endDate.clone().subtract(1, 'week').toDate();
                        this.maxDate = this.endDate.clone().toDate();
                        break;
                    case ReportFilter.STATE:
                        this.selectedStates = routerFilter.value as State[];
                        break;
                }
            });
        }

        if (!routeFilters) {
            this.snackbar.open('To avoid potential long load time, default locations have not been selected.', 'DISMISS', {
                duration: 5000,
                verticalPosition: 'top',
            });
        }
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
    }

    public ngAfterViewInit(): void {
        // Doing this to always scroll to top of page. Revisit after another report is redesigned.
        document.getElementsByClassName('filter-bar')[0].scrollIntoView();

        this.initializeLineChart();
        this.getOutages();
    }

    // Data Methods
    /**
     * API call to get the restoration numbers
     */
    public getOutages(): void {
        this.isLoading = true;
        this.clearTable();

        if (this.selectedStates.length <= 0) {
            this.isLoading = false;
            return;
        }

        this.clearInterceptor();

        const filterList = this.selectedStates.map((s) => s.abbreviation);
        const filterLevel = 'state';

        const apiDate = moment(this.endDate).endOf('day').add(1, 'second');

        HttpInterceptorService.pendingRequests['restoration'] = this.reportService
            .getRestorationData(moment(apiDate).subtract(7, 'days').startOf('day'), moment(apiDate), filterLevel, filterList, this.interval)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests['restoration'];

                const outageData: any[] = [];
                res.data.forEach((o) => moment(o.outageRunStartTime));
                this.chartData = res.data;
                res.data.forEach((d) => {
                    const findEvery =
                        this.level === 'county'
                            ? (o) => o.every((f) => f.countyId === d.countyId)
                            : this.level === 'state'
                            ? (o) => o.every((f) => f.state === d.state)
                            : (o) => o.every((f) => f.femaRegion === d.femaRegion);
                    if (outageData.some((o) => findEvery(o))) {
                        outageData.find((o) => findEvery(o)).push(d);
                    } else {
                        outageData.push([d]);
                    }
                });

                outageData.forEach((d, index) => {
                    outageData.splice(index, 1, new OutageRestoration(d));
                });
                this.initializeData(outageData);

                this.renderChart();

                this.isLoading = false;
            });
    }

    // Table Methods
    /**
     * Creates the DataSource for the table to use the restoration numbers
     * @param data Restoration numbers being used by table
     */
    private initializeData(data: OutageRestoration[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<OutageRestoration>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.dataSource.filter = ' ';
    }

    /**
     * Checks each outage restoration to filter
     * @param data Outage Restoration to filter
     */
    private filterPredicate(data: OutageRestoration): boolean {
        return this.selectedStates.map((s) => s.abbreviation).includes(data.state);
    }

    /**
     * Checks each outage restoration to be sorted
     * @param data Outage Restoration to be sorted
     * @param column Column beign sorted
     */
    public dataAccessor(data: OutageRestoration, column: string): string | number {
        switch (column) {
            case 'fema':
                return data.femaRegion;
            case 'state':
                return data.state;
            case 'county':
                return data.countyName;
            case 'outageCount':
                return data.outageCountTotal;
            case 'restorationCount':
                return data.restorationTotal;
            case 'percentRestored':
                return data.percentRestored;
            case 'maxOutage':
                return data.maxOutageCount;
            case 'maxRestoration':
                return data.maxRestorationCount;
            case 'lastTimestamp':
                return data.timestamp.valueOf();
            default:
                return '';
        }
    }

    /**
     * Clears the table of it's data
     */
    private clearTable(): void {
        if (this.dataSource) {
            this.dataSource.data = [];
        }
    }

    // Filter Methods
    /**
     * Updates the aggregation and updates the column names to the correct columns
     */
    public updateLevel(getOutages: boolean = true): void {
        if (this.level === 'state') {
            this.columnNames = [
                'state',
                'outageCount',
                'restorationCount',
                'percentRestored',
                'trend',
                'maxOutage',
                'maxRestoration',
                'lastTimestamp',
            ];
        } else {
            this.columnNames = [
                'state',
                'county',
                'outageCount',
                'restorationCount',
                'percentRestored',
                'trend',
                'maxOutage',
                'maxRestoration',
                'lastTimestamp',
            ];
        }

        if (getOutages) {
            this.getOutages();
        }
    }

    /**
     * Updates the selected states
     * @param states States to be changed
     */
    public updateRegionFilter(states: State[]): void {
        this.selectedStates = states;

        this.getOutages();
    }

    /**
     * Updates to the given end date
     * @param event End date to be updated to
     */
    public updateDate(event: any): void {
        this.endDate = moment(event);
        this.eDate = this.endDate.toDate();
        this.getOutages();
    }

    /**
     * Updates the chips for the selected states
     * @param states States selected
     */
    public changeSelectedChips(states: State[]): void {
        this.selectedStates = states.slice();
        this.getOutages();
    }

    // Chart Methods
    /**
     * Creates the Line chart
     */
    private initializeLineChart(): void {
        this.baseChart.interactivePopover = false;
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers');

        this.baseChart.getXAxis().axisLabel.formatter = (date) => {
            const value = parseInt(date);
            const formatType = this.interval === 24 ? 'M/D/YY' : 'M/D/YY h A';
            return moment(value).format(formatType);
        };

        this.baseChart.setOptions();
    }

    /**
     * Renders the Chart
     */
    private renderChart(): void {
        this.isLoading = true;

        const outageLine = this.getLineChartDataSummary('outage');
        const resLine = this.getLineChartDataSummary('restoration');
        const newLine = this.getLineChartDataSummary('new');

        if (!resLine && !outageLine && !newLine) {
            return;
        }

        const transform = (val) => Report.momentPipe.transform(val, this.interval === 24 ? 'M/D/YYYY' : 'M/D/YYYY hh A');

        const getxValues = (bounds) => bounds.data.map((dp) => moment(dp.data.x).valueOf());
        const getyValues = (bounds) =>
            bounds.data.map((dp) => {
                return {
                    value: dp.data.y,
                    popoverData: [
                        new PopoverElement(
                            'Title',
                            `${transform(dp.data.x)}${
                                this.interval !== 24 ? ' - ' + transform(moment(dp.data.x).add(this.interval, 'hours')) : ''
                            }`
                        ),
                        new PopoverElement(`${dp.data.displayType}`, `${Report.numberPipe.transform(dp.data.y)}`),
                    ],
                    exportData: {
                        type: dp.data.displayType,
                        yValue: dp.data.y,
                        xValue: moment(dp.data.x).format(),
                    },
                };
            });

        this.baseChart.eChartOptions.xAxis['data'] = getxValues(resLine);
        this.baseChart.eChartOptions.xAxis['name'] = 'Date';

        this.baseChart.eChartOptions.series = [
            {
                type: 'line',
                itemStyle: {color: '#97CFFD'},
                lineStyle: {color: '#97CFFD'},
                data: getyValues(outageLine),
            },
            {
                type: 'line',
                itemStyle: {color: '#7CFA17'},
                lineStyle: {color: '#7CFA17'},
                data: getyValues(resLine),
            },
            {
                type: 'line',
                itemStyle: {color: '#9D81DE'},
                lineStyle: {color: '#9D81DE'},
                data: getyValues(newLine),
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
        this.isLoading = false;
    }

    /**
     * Creates the line data for the chart to use
     * @param type The type of line to be created
     */
    private getLineChartDataSummary(type: 'outage' | 'restoration' | 'new'): ChartBounds<any> {
        if (this.dataSource.filteredData.length <= 0) {
            return;
        }

        const getKey = (o: OutageRestoration) => moment(o.outageRunStartTime).valueOf();
        const getYValue = (o: OutageRestoration) =>
            type === 'outage' ? o.outageCount : type === 'restoration' ? o.restorationCount : o.newOutageCount;

        const createDataPoint = (o: OutageRestoration) => {
            const d = {
                x: moment(o.outageRunStartTime).valueOf(),
                y: 0,
                timeStamp: moment(o.outageRunStartTime),
                lineColor: type === 'outage' ? '#97CFFD' : type === 'restoration' ? '#7CFA17' : '#9D81DE',
                displayType: type === 'outage' ? 'Total Outages' : type === 'restoration' ? 'Restored' : 'New Outages',
                exportData: {
                    type: type === 'outage' ? 'Total Outages' : type === 'restoration' ? 'Restored' : 'New Outages',
                    xValue: moment(o.outageRunStartTime).valueOf(),
                    yValue: 0,
                },
            };
            return new DataPoint(d);
        };

        const updateDataPoint = (dp: DataPoint, res: OutageRestoration) => {
            dp.data.y += getYValue(res);
            dp.data.exportData.yValue += getYValue(res);
        };

        const data = this.chartData;

        const bounds = new ChartBounds<DataPoint, OutageRestoration>();

        bounds.rawData = this.chartData;
        bounds.data = this.chartService.getTimeLine(data, getKey, createDataPoint, updateDataPoint);

        return bounds;
    }

    // Export Methods
    /**
     * Exports the table as a CSV
     */
    public exportTable(): void {
        const aggPipe = new AggregationLevelPipe();
        let data = '';
        const headerColumns = this.columnNames.slice();

        data += headerColumns
            .map((val) => {
                val =
                    val === 'fema' || val === 'state'
                        ? aggPipe.transform(val === 'fema' ? GenOutageAggregationLevel.fema : GenOutageAggregationLevel.state)
                        : val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            const levelVal =
                this.level === 'state'
                    ? FileDownload.formatCsvCell(val.state)
                    : `${FileDownload.formatCsvCell(val.state)},${FileDownload.formatCsvCell(val.countyName)}`;

            const values = [
                FileDownload.formatCsvCell(val.outageCountTotal),
                FileDownload.formatCsvCell(val.restorationTotal),
                FileDownload.formatCsvCell(val.percentRestored + '%'),
                FileDownload.formatCsvCell(val.trendIcon),
                FileDownload.formatCsvCell(val.maxOutageCount),
                FileDownload.formatCsvCell(val.maxRestorationCount),
                FileDownload.formatCsvCell(moment(val.timestamp).format('M/D/YYYY')),
            ];

            data += `${levelVal},${values.join()}`;
            data += '\n';
        });

        FileDownload.downloadCSV('restorationDataTable', data, this.attributionUrl);
    }

    /**
     * Exports the chart as a CSV
     */
    public exportChartAsCsv(): void {
        let data = '';
        const columns = ['Type', 'Date', 'Customers'];

        data += columns.join() + '\n';
        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((sd) => {
                data +=
                    [
                        FileDownload.formatCsvCell(sd.exportData.type),
                        FileDownload.formatCsvCell(sd.exportData.xValue),
                        FileDownload.formatCsvCell(sd.exportData.yValue),
                    ].join() + '\n';
            });
        });

        data += '\n\n';
        FileDownload.downloadCSV('restorationDataChart', data, this.attributionUrl);
    }

    /**
     * Exports the Chart as a PNG
     */
    public exportChart(): void {
        const trueDate = moment(this.endDate).endOf('day').add(1, 'second');
        const title =
            `Restoration from ${Report.momentPipe.transform(moment(trueDate).subtract(7, 'days').startOf('day'), 'M/D/YYYY')}` +
            ` to ${Report.momentPipe.transform(moment(trueDate).subtract(1, 'day'), 'M/D/YYYY')} (${this.interval}-Hr Increments)`;

        FileDownload.exportChartAsPNG(this.baseChart.eChart, 'restorationChart', title, this.attributionUrl);
    }

    // Utility Methods

    /**
     * Gets the Mask Text
     */
    public getMaskText(): string {
        return this.isLoading ? 'Loading ...' : this.selectedStates.length <= 0 ? 'Select Filter Locations' : 'No Data Match Filters';
    }

    public getExportTooltip(): string {
        return !this.showMask ? 'Export' : `Can't export when there is no data`;
    }

    public getCountyCustomerText(info: any): string {
        return !!info.totalCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    /**
     * Opens the restoration Info modal
     */
    public restorationInfo(): void {
        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            disableClose: true,
            autoFocus: false,
        };

        this.dialog.open(RestorationInfoModalComponent, config);
    }

    /**
     * Clear the HTTP Interceptor
     */
    public clearInterceptor(): void {
        if (HttpInterceptorService.pendingRequests['restoration']) {
            HttpInterceptorService.pendingRequests['restoration'].unsubscribe();
        }
    }
}
