<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select active">
            <mat-label>Event</mat-label>
            <mat-select [formControl]="eventControl">
                <mat-optgroup *ngFor="let group of waoEvents | keyvalue" [label]="group.key | titlecase">
                    <mat-option *ngFor="let event of group.value" [value]="event">{{ event.name }}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select active">
            <mat-label>Date</mat-label>
            <mat-select [formControl]="dateControl" [compareWith]="dateCompare">
                <mat-option *ngFor="let date of dateList" [value]="date">{{ date | momentDate : 'MM-DD-YYYY' : false : true }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select active">
            <mat-label>Light Status</mat-label>
            <mat-select multiple [formControl]="lightControl">
                <mat-option [value]="-1">No Lights</mat-option>
                <mat-option [value]="1">Increased Lights</mat-option>
                <mat-option [value]="3">Cloud</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export" inline></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="map"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #map>
    <div class="map-section">
        <mat-card class="map">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapOptions"></eaglei-leaflet-map>
            </mat-card-content>

            <mat-card-footer>
                <div class="legend">
                    <div class="legend-label">
                        <span class="label">No Lights</span>
                        <div class="icon no-light"></div>
                    </div>
                    <div class="legend-label">
                        <span class="label">Increased Lights</span>
                        <div class="icon increased"></div>
                    </div>
                    <div class="legend-label">
                        <span class="label">Cloud</span>
                        <div class="icon cloud"></div>
                    </div>
                </div>
            </mat-card-footer>
        </mat-card>
    </div>
</ng-template>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-lg-column">
                            State
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-lg-column">
                            {{ info.state?.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="county">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-lg-column">
                            County
                            <eaglei-table-sort-header [columnName]="'county'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-lg-column">
                            {{ info.countyName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="structure">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                            Structure Count
                            <eaglei-table-sort-header [columnName]="'structure'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-md-column">
                            {{ info.structureCount }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="light">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                            Light Status
                            <eaglei-table-sort-header [columnName]="'light'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-md-column">
                            {{ getLightStatus(info.lightStatus) }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
