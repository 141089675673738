<!-- Filters-->
<div class="filter-bar ignore-last">
    <div class="filters">
        <mat-form-field class="filter-select center">
            <mat-label>Event</mat-label>
            <mat-select placeholder="Event" [formControl]="eventControl">
                <mat-option *ngFor="let event of events" [value]="event">{{ event.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select center">
            <mat-label>Report</mat-label>
            <mat-select placeholder="Report" [formControl]="reportControl">
                <mat-option
                    *ngFor="let report of reports"
                    [matTooltip]="report.advisory"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    [value]="report"
                    >{{ report.advisory }}
                </mat-option>
            </mat-select>

            <mat-hint>
                <span class="fake-link" (click)="openInputModal($event)">View input information</span>
            </mat-hint>
        </mat-form-field>

        <mat-form-field class="filter-select center">
            <mat-label>Disruption Type</mat-label>
            <mat-select placeholder="Disruption Type" [formControl]="typeControl" multiple>
                <mat-option *ngFor="let type of disruptionTypes" [value]="type">{{ type | replaceCharacter | titlecase }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="btn-container">
        <button *ngIf="isContentManager" mat-stroked-button (click)="openDialog()">Upload Analysis</button>

        <button
            class="export"
            mat-stroked-button
            matTooltip="Export"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [matMenuTriggerFor]="menu">
            <fa-icon icon="file-export"></fa-icon>
        </button>
    </div>
</div>

<div class="modeled-data-disclaimer">All Assets shown and disrupted are determined by proximity and are only used for modeling output</div>

<div class="map-section">
    <ng-container [ngTemplateOutlet]="nomMap"></ng-container>
</div>

<div class="table-section">
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!--Map-->
<ng-template #nomMap>
    <mat-card class="map" [class.invisible]="mapMask.showMask">
        <mat-card-content>
            <eaglei-leaflet-map (mapRef)="initializeMap($event)" [mapOptions]="mapOptions"></eaglei-leaflet-map>
            <div class="polygon-controls">
                <p>Area of Effect Opacity: {{ aoeOpacity }}%</p>
                <mat-slider min="0" max="100" step="1" color="primary">
                    <input matSliderThumb [value]="aoeOpacity" (valueChange)="updateAoeOpacity($event)" />
                </mat-slider>
            </div>

            <div class="zoomed-asset-controls" *ngIf="beforeZoomState">
                <span class="fake-link" (click)="clearSelectedAsset()"> Clear selected asset </span>
            </div>
        </mat-card-content>
        <mat-card-footer>
            <div>
                <div class="circle" style="background-color: #228b22"></div>
                <span>Substation</span>
            </div>

            <div>
                <div class="circle" style="background-color: #1c4966"></div>
                <span>Power Plant</span>
            </div>

            <div>
                <div class="circle" style="background-color: #ff0000"></div>
                <span>Impacted Assets</span>
            </div>

            <div>
                <div class="line"></div>
                <span>Transmission Line</span>
            </div>

            <div>
                <div class="polygon" style="border-color: #707070; background-color: rgba(112, 112, 112, 0.1)"></div>
                <span>Area of Effect</span>
            </div>
        </mat-card-footer>
    </mat-card>

    <div class="auto-mask hide-mask" *ngIf="mapMask.showMask">
        <span class="mask-message">{{ mapMask.text }}</span>
    </div>
</ng-template>

<!--Table-->
<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="auto-mask hide-mask" *ngIf="mapMask.showMask">
                <span class="mask-message">{{ mapMask.text }}</span>
            </div>

            <div class="table-container" [class.invisible]="mapMask.showMask">
                <table
                    #materialTable
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="total"
                    matSortDirection="desc"
                    multiTemplateDataRows>
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="ei-icon-column">
                            <mat-checkbox
                                color="primary"
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="ei-icon-column">
                            <mat-checkbox
                                color="primary"
                                (click)="$event.stopPropagation()"
                                (change)="toggleRow($event, row)"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Name</span>
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td
                            #disruptionName
                            mat-cell
                            *matCellDef="let disruption"
                            class="ellipsis"
                            [matTooltip]="disruption.properties.name"
                            [matTooltipDisabled]="!isEllipsis(disruptionName)">
                            {{ disruption.properties.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Disruption Type
                            <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let disruption">
                            <span> {{ disruption.properties.type | replaceCharacter | titlecase }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="communications">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.COMMUNICATIONS.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Communications</span>
                                <eaglei-table-sort-header [columnName]="'communications'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.COMMUNICATIONS.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="food_water_shelter">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.FOOD_WATER_SHELTER.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Food Water Shelter</span>
                                <eaglei-table-sort-header [columnName]="'food_water_shelter'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.FOOD_WATER_SHELTER.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="other">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                            <span>Other</span>
                            <eaglei-table-sort-header [columnName]="'other'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.OTHER.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="energy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                            <div class="tooltip-header">
                                <fa-icon icon="info-circle" [matTooltip]="tooltipMap[femaTypes.ENERGY.name]" matTooltipPosition="above">
                                </fa-icon>
                                <span>Energy</span>
                                <eaglei-table-sort-header [columnName]="'energy'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.ENERGY.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="safety_and_security">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.SAFETY_AND_SECURITY.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Safety and Security</span>
                                <eaglei-table-sort-header [columnName]="'safety_and_security'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.SAFETY_AND_SECURITY.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="health_and_medical">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.HEALTH_AND_MEDICAL.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Health and Medical</span>
                                <eaglei-table-sort-header [columnName]="'health_and_medical'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.HEALTH_AND_MEDICAL.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="transportation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.TRANSPORTATION.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Transportation</span>
                                <eaglei-table-sort-header [columnName]="'transportation'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.TRANSPORTATION.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="hazardous_material">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="right-cell">
                            <div class="tooltip-header">
                                <fa-icon
                                    icon="info-circle"
                                    [matTooltip]="tooltipMap[femaTypes.HAZARDOUS_MATERIAL.name]"
                                    matTooltipPosition="above">
                                </fa-icon>
                                <span>Hazardous Material</span>
                                <eaglei-table-sort-header [columnName]="'hazardous_material'" [sort]="sort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="right-cell">
                            <span> {{ disruption.properties.counts[femaTypes.HAZARDOUS_MATERIAL.name] }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-sm-column right-cell">
                            <span>Total</span>
                            <eaglei-table-sort-header [columnName]="'total'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let disruption" class="ei-sm-column right-cell">
                            <span> {{ disruption.properties.counts['TOTAL'] }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let info" [attr.colspan]="columns.length">
                            <div class="hide-expanded-row" [class.show-expanded-row]="selectedRow === info">
                                <eaglei-affected-asset-table
                                    *ngIf="selectedRow === info"
                                    [affectedAssets]="selectedRow.properties.effects"></eaglei-affected-asset-table>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columns; let odd = odd"
                        class="clickable"
                        (click)="toggleExpandedRow(row)"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>
            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button mat-menu-item (click)="exportTable()">Export Table As CSV</button>
    <button mat-menu-item (click)="exportSelectedDisruptions()">Export Selected Disruptions As CSV</button>
</mat-menu>
