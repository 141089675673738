<div class="filter-bar ignore-last" [formGroup]="filterControlGroup">
    <section class="filters">
        <mat-form-field class="filter-select">
            <mat-label>Infrastructure Type</mat-label>
            <mat-select (selectionChange)="updateFilter()" formControlName="infrastructure">
                <mat-option value="all">All</mat-option>
                <mat-option value="pole">Utility Pole</mat-option>
                <mat-option value="substation">Substation</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-select">
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="updateFilter()" formControlName="status">
                <mat-option value="all">All</mat-option>
                <mat-option value="damaged">Damaged</mat-option>
                <mat-option value="undamaged">Undamaged</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="map"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #map>
    <section class="map-section">
        <mat-card class="map">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapOptions"></eaglei-leaflet-map>
            </mat-card-content>

            <mat-card-footer>
                <div class="legend">
                    <span class="legend-label">Utility Pole: </span>
                    <div class="legend-item">
                        <img class="pole-icon" src="/dist/browser/images/pole-detection/pole_circle_green.svg" />
                        <span class="label">Undamaged</span>
                    </div>
                    <div class="legend-item">
                        <img class="pole-icon" src="/dist/browser/images/pole-detection/pole_circle_red.svg" />
                        <span class="label">Damaged</span>
                    </div>
                </div>

                <div class="legend">
                    <span class="legend-label">Substation: </span>
                    <div class="legend-item">
                        <img class="pole-icon" src="/dist/browser/images/pole-detection/substation_circle_green.svg" />
                        <span class="label">Undamaged</span>
                    </div>
                    <div class="legend-item">
                        <img class="pole-icon" src="/dist/browser/images/pole-detection/substation_circle_red.svg" />
                        <span class="label">Damaged</span>
                    </div>
                </div>
            </mat-card-footer>
        </mat-card>
    </section>
</ng-template>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
                    <ng-container matColumnDef="infrastructure">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ei-md-column">
                            Infrastructure
                            <eaglei-table-sort-header [columnName]="'infrastructure'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="ei-md-column">
                            <span> {{ info.infrastructure }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="poleId">
                        <th mat-header-cell *matHeaderCellDef>Pole ID</th>
                        <td mat-cell *matCellDef="let info">
                            <span> {{ info.poleId }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="callSign">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Call Sign
                            <eaglei-table-sort-header [columnName]="'callSign'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            <span>{{ info.callSign }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Type
                            <eaglei-table-sort-header [columnName]="'type'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.type }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="flight_date">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Flight Date
                            <eaglei-table-sort-header [columnName]="'flight_date'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.flightDate | momentDate }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="learning_model">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Learning Model
                            <eaglei-table-sort-header [columnName]="'learning_model'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.learningModel }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="detections">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            # of Detections
                            <eaglei-table-sort-header [columnName]="'detections'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.detections }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="damaged">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.damaged ? 'Damaged' : 'Undamaged' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="confidence">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Confidence
                            <eaglei-table-sort-header [columnName]="'confidence'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">
                            {{ info.confidence | percent : '0.0-2' }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        (click)="zoomToFeature(row)"
                        class="clickable"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
</mat-menu>
