import {LeafletVectorSource} from '../../../app/modules/layer/classes/leaflet-vector-source';
import {UrbannetDisruption} from './urbannet-disruption';
import {LeafletFeature} from '../../../app/modules/layer/classes/leaflet-feature';
import L from 'leaflet';
import {MapService} from '../../../app/modules/map/services/map.service';
import {LayerStyleService} from '../../../app/modules/layer/services/layer-style.service';
import {LeafletMapLayer} from '../../../app/modules/layer/classes/leaflet-map-layer';
import {GenServiceType} from '../../../../generated/serverModels/GenServiceType';

export class UrbannetSource extends LeafletVectorSource<UrbannetDisruption> {
    constructor() {
        super(
            new LeafletMapLayer({
                displayName: 'URBAN-NET',
                servicetype: GenServiceType.VECTOR,
                uiHandle: `urbannet-disruption`,
                attributionTitle: '',
                attributionUrl: '',
            } as any)
        );
    }

    processFeatures(features: UrbannetDisruption[]): void {
        this.leafletFeatures = features.map((f) => new LeafletFeature().convert(f, 'geometry'));

        const style = (feature: LeafletFeature<UrbannetDisruption>, latlng: L.LatLng): L.Layer => {
            return L.circleMarker(latlng, this.getStyle(feature));
        };

        const layerPopover = (feature: LeafletFeature<UrbannetDisruption>, layer: L.GeoJSON) => {
            this.initializePopoverInteractions(feature, layer, 'click', false, feature.properties.popoverData);
            switch (feature.properties.type) {
                case 'Transmission_Lines':
                    layer.setStyle(this.getLineStyle());
                    break;
            }
        };

        const config: any = {
            pane: MapService.layerPaneName,
            pointToLayer: style,
            onEachFeature: layerPopover,
            features: this.leafletFeatures,
        };

        this.source = L.geoJSON(this.leafletFeatures as any, config);
    }

    private getStyle(feature: LeafletFeature<UrbannetDisruption>): any {
        const key = feature.properties.type;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            const fillColor = key.toLowerCase().includes('substation') ? '#228B22' : '#1C4966';

            style = {
                radius: 4,
                color: '#000',
                fillColor,
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }

    private getLineStyle(): any {
        const key = `testLine`;
        let style = LayerStyleService.layerStyles.get(key);
        if (!style) {
            style = {
                color: '#69c2f9',
                stroke: true,
                weight: 2,
                opacity: 1,
                // fillOpacity: 0.8,
            };

            LayerStyleService.layerStyles.set(key, style);
        }
        return style;
    }
}
