import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import moment from 'moment';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {RecentEvent} from '../../../recent-events/classes/recent-event';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {RecentEventSourceType} from '../../../recent-events/enums/recent-event-source-type';
import {FileDownload} from '../../../../classes/file-download';

@Component({
    selector: 'eaglei-iso-notices-report',
    templateUrl: './iso-notices-report.component.html',
    styleUrls: ['../reports.scss', './iso-notices-report.component.scss'],
})
export class IsoNoticesReportComponent extends Report<RecentEvent> implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;

    // Filter Properties
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();

    // Table Properties
    private activeEvent: RecentEvent;
    public readonly columns: string[] = ['title', 'date'];

    constructor(private reportService: ReportService, private host: ElementRef) {
        super();

        this.reportService.getRecentEventAttribution(RecentEventSourceType.ISO).subscribe((sources) => {
            this.attributionUrl = sources.map((source) => source.apidomain).join();
        });
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
        this.getNotices();
    }

    // API Methods

    private getNotices() {
        this.reportService.getRecentEventData(RecentEventSourceType.ISO, moment(this.startDate), moment(this.endDate)).subscribe((res) => {
            this.initializeData(res.data);
        });
    }

    // Table Methods
    /**
     * Creates the data source with sorting, filtering, and paging and initializes the data.
     * If the dataSource already exists only the data is set
     * @param data The data to be displayed in the table.
     */
    private initializeData(data: RecentEvent[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Defines the logic for how the table will be filtered on text input
     * @param data the RecentEvent being filtered
     * @param text The search text from the view
     */
    private filterPredicate(data: RecentEvent, text: string): boolean {
        return data.title.toLowerCase().includes(text.trim().toLowerCase());
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Defines the logic on how the table is sorted
     * @param data the RecentEvent being sorted
     * @param header The headed column being sorted on
     */
    private dataAccessor(data: RecentEvent, header: string): string | number {
        switch (header) {
            case 'title':
                return data.title.trim().toLowerCase();
            case 'date':
                return data.newsDate.valueOf();
            default:
                return '';
        }
    }

    /**
     * Checkes to see if an event is the currently expanded event.
     * @param info The RecentEvent being tested
     */
    public isActiveEvent(info: RecentEvent): boolean {
        return this.activeEvent && this.activeEvent.id === info.id;
    }

    /**
     * Sets the activeEvent to the row passed in, if the active event is already set to that row. The activeEvent is set to undefiend
     * @param row The row to be expanded
     */
    public toggleActiveReport(row: RecentEvent): void {
        this.activeEvent = this.isActiveEvent(row) ? undefined : row;
    }

    // Filter methods
    /**
     * Listends to the view daterange and updates the dates and recalls all API's
     * @param dates The new dates from the date range
     */
    public updateDateRange(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.getNotices();
    }

    /**
     * Returns if there is text in the text search box
     */
    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter && this.dataSource.filter.trim().length > 0;
    }

    /**
     * calls the table filter method with the updated filters.
     * @param text The text being searched
     */
    public filterData(text: string): void {
        text = text === '' ? ' ' : text;
        this.dataSource.filter = text;
    }

    // Modal Methods
    /**
     * Opens a modal that will show all the attribution URL's being used for this report.
     */
    public getSourceData(): void {
        this.reportService.getRecentEventSourceData(RecentEventSourceType.ISO, 'ISO Notices', this.host.nativeElement.clientWidth * 0.8);
    }

    // Export Methods
    /**
     * Exports the table as a CSV file.
     */
    public exportTable(): void {
        let data = '';

        data += this.columns
            .map((val) => {
                val = val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource.filteredData.forEach((notice) => {
            const info = [
                FileDownload.formatCsvCell(notice.title),
                FileDownload.formatCsvCell(Report.momentPipe.transform(notice.newsDate)),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('isoNotices', data, this.attributionUrl);
    }
}
